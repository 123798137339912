<template>
    <label class="form-label">Организация*</label>
    <VueMultiselect
        v-model="organization"
        placeholder="Выберите организацию"
        label="name"
        track-by="id"
        :options="organizations"
        :show-labels="false"
        :searchable="false"
        @select="filterOrganization($event)"
        @remove="reset($event)"
    ></VueMultiselect>

    <label class="form-label">Родительская роль*</label>
    <VueMultiselect
        v-model="role"
        placeholder="Выберите роль"
        label="title"
        track-by="id"
        :options="all_roles"
        :show-labels="false"
        :searchable="false"
    ></VueMultiselect>

    <Input
        v-model:modelValue="title"
        @clear="clearInput($event)"
        :error="[errors.title.length ? 'error' : '']"
        label="Название роли"
        name="role_title"
        type="text"
        placeholder="Название роли"
        id="role_title"
    />

    <Button
        caption="Сохранить"
        color="btn-green"
        add_class=""
        @click="save()"
    />
</template>

<script>
import Input from '../../components/Input';
import Button from '../../components/Button';
import VueMultiselect from "vue-multiselect";

export default {
    name: "FormRole",
    components: { Input, Button, VueMultiselect },
    props: {
        title: {
            type: String,
            default: ''
        },
        organization: null,
        role: null,
        id: null,
        organizations: [],
        roles: [],
        all_roles: [],
        errors: {
            title: '',
        }
    },
    methods: {
        save() {
            this.$emit('save', {
                id: this.id,
                title: this.title,
                organization: this.organization,
                role: this.role,
            })
        },
        clearInput(e){
            this.errors[e] = '';
        },
        filterOrganization(event){
            this.$emit('filter', event)
        },
        reset(event){
            this.$emit('reset', event)
        }
    }
}
</script>

<style scoped>

</style>
