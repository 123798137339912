<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="d-flex flex-wrap" v-if="this.defects.length > 0">
                    <h5 class="mb-3"><fa icon="list-check" class="icon" style="padding-right: 10px; color: #c00;"></fa>{{ itemName }}</h5>
                    <div class="col-12 item_title"
                        v-for="defect in defects"
                        :key="defect.id"
                        @click="getDefect(defect.id)"
                    >
                    
                        <!-- Время от и до -->
                        <div class="gn_time_row d-flex justify-content-between">
                            <div class="gn_time_start text-black-50 fs-7">
                                <fa icon="clock" class="icon text-black-50"></fa>
                                {{ defect.date_verification }}
                            </div>
                            <div class="gn_time_arrow">
                                <fa icon="angles-right" class="icon"></fa>
                            </div>
                            <div class="gn_time_end text-black-50 fs-7">{{ defect.term_execution }}</div>
                        </div>

                        <!-- Категория -->
                        <div class="gn_item mt-2">
                            <div class="gn_row d-flex gn_inner">
                                <div class="gn_label pe-2 text-black-50"><fa icon="list" class="icon"></fa></div>
                                <div class="gn_value">{{ defect.subitem.name }}</div>
                            </div>    
                        </div>
                    
                    </div>
                </div>
                
             
                

            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "TSMDefectsPage",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            item: '',
            defects: {},
            term: '',
            expire: '',
            car_number: null,
            itemName: '',
            breadCrumbs: true,
            title: 'Список несоответствий',
            back_title: 'Группы несоответствий',
            link: 'tsm-defects',
        }
    },
    mounted() {
        this.getListDefect();
    },
    methods: {
        getDefect(id) {
            this.$router.push(`/tsm-defects/${this.$route.params.item_id}/${id}`);
        },
        async getListDefect() {
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tsm/get/defects/${this.$route.params.item_id}`, config);
                this.defects = response.data.data.checkings;
                this.itemName = response.data.data.itemName;
                if(this.defects.length === 0){
                    this.$router.push('/tsm-defects');
                }
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>
