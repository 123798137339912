<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div class="container-fluid login_top">
            <div class="row">
                <div class="login_page_wrap align-items-center d-flex h-100 justify-content-start position-absolute col-12 g-0" >
                    <div class="align-items-center col-12 col-lg-5 col-md-6 col-xl-4 col-xxl-3 d-flex h-100 justify-content-center login_box">
                        <div class="auth_box">
                            <Form ref="form" @submit="resetPassword">
                                <div class="logo">
                                    <img class="mb-4 logo-img" src="/image/logo.png" alt="">
                                </div>
                                <div class="fs-7 fw-normal text-center mb-3 opacity-75" style="max-width: 295px;">
                                    Проверьте Email. В письме для сброса пароля пройдите по ссылке. После установки нового паролья, авторизутесь на странице входа.
                                </div>
                                                                
                                <button class="w-100 btn gray mt-4" @click="gotoStart()">
                                    <span class="material-symbols-rounded me-2 opacity-50">undo</span>
                                    Вход
                                </button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../components/Spinner";

export default {
    name: "AfterSend",
    components: {},
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        gotoStart() {
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>

</style>
