<template>
    <div class="popup">
        <div class="popup-content">
            <div class="close float-end" @click="close">
                <span class="material-symbols-rounded">close</span>
            </div>

            <h6 class="mb-3">{{ name }}</h6>
            <hr>
            <template v-if="wear.length > 0">
                <h6 class="mb-3 hstack">
                    <span class="material-symbols-rounded me-2 opacity-50">sync_problem</span>
                    Износ
                </h6>
                <div class="fs-7 fw-normal ms-0 my-3">1 &ndash; очень плохо, 5 &ndash; отлично.</div>
                <div class="item_title">
                    <div class="d-flex mb-2 hstack gap-2" v-for="items in wear">
                        <div
                            class="btn w-100"
                            v-for="item in items"
                            :key="item.id"
                            :class="item.select === true ? 'btn-red' : 'gray'"
                            @click="addDefect(item.id, itemId, 'wear')">
                                {{ item.parameter }}
                        </div>
                    </div>
                </div>
            </template>

            <!-- Внутренний осмотр -->
            <template v-if="damage.length > 0">
                <h6 class="mt-4 mb-3 hstack">
                    <span class="material-symbols-rounded me-2 opacity-50">rule</span>
                    Повреждения
                </h6>

                <div class="vstack justify-content-center mt-0">
                    <div
                        v-for="item in damage"
                        :key="item.id"
                        @click="addDefect(item.id, itemId, 'damage')">
                        <div
                            class="btn cl_item mb-2 w-100 text-center"
                            :class="item.select === true ? 'active_defect' : ''">
                                <span class="btn_damage_character">{{ item.character }}</span>
                                <div class="defect_title">{{ item.parameter }}</div>
                        </div>
                    </div>
                </div>
            </template>
            
            <!-- Подкапотное пространство -->
            <template v-if="smudges.length > 0">

                <div class="fs-7 fw-normal my-3">Укажите, имеются ли следы масла в подкапотном пространстве или на двигателе?</div>

                <div
                    v-for="item in smudges"
                    :key="item.id"
                    @click="addDefect(item.id, itemId, 'smudges')">

                    <template v-if="item.parameter == 'Да'">
                        <div class="btn cl_item mb-2 w-100 text-center" :class="item.select === true ? 'active_defect' : ''">
                            {{ item.parameter }}
                        </div>
                    </template>
                    <template v-else :on-load="(item.select = true)">
                        <div class="btn cl_item mb-2 w-100 text-center" :class="item.select === true ? 'type_accept' : ''">
                            {{ item.parameter }}
                        </div>
                    </template>
                </div>
            </template>

                <!-- <div class="btn cl_item mb-2 w-100 text-center" :class="item.select ===
            true ? 'active_defect' : ''"> <span class="btn_damage_character">{{
            item.character }}</span> <div class="defect_title">{{ item.parameter }}</div>
            </div> -->

            <!-- Фотографии -->
            <h6 class="hstack mt-4 mb-3">
                <span class="material-symbols-rounded me-2 opacity-50">photo_camera</span>
                Фото повреждения
            </h6>
            <div class="item_title">
                <div class="photo_defect">
                    <div class="block_foto" v-if="!showPreview">
                        <div v-if="Object.keys(image).length !== 0">
                            <div v-for="(file, key) in image" class="image_block" v-bind:key="'file-'+key">
                                {{ key }}
                                <img class="defect_image" data-fancybox="gallery" :src="`${ file }`"/>
                            </div>
                        </div>
                        <div v-else class="pb_image">
                            <span class="material-symbols-rounded">photo_camera</span>
                        </div>
                    </div>
                    <div class="block_foto align-middle d-flex justify-content-center align-items-center" v-else>
                        <div style="padding:  unset;">
                            <div v-for="(file, key) in files" class="image_block position-relative" v-bind:key="'file-'+key">
                                
                                <!-- Кнопка удаления фото -->
                                <span class="material-symbols-rounded delete_image">delete_forever</span>
                                
                                <img
                                    style="width: 100%;"
                                    class="defect_image"
                                    data-fancybox="gallery"
                                    v-bind:id="'image-'+parseInt( key )"/>
                                <input type="hidden" name="photo" v-bind:id="'photo-'+parseInt( key )"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isDelete">
                    <form enctype="multipart/form-data">
                        <div class="btn gray w-100">
                            <input
                                type="file"
                                id="files"
                                class="inputfile"
                                multiple="multiple"
                                accept="image/*"
                                ref="files"
                                @change="onAttachmentChange"
                                style="display: none"/>
                            <label for="files" class="delete_image is_delete">
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5 2V0H15V2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5ZM4 4V18H16V4H4ZM7 7H9V15H7V7ZM11 7H13V15H11V7Z"
                                        fill="white"/>
                                </svg>
                                Удалить фото
                            </label>
                        </div>
                    </form>
                </div>
                <div v-if="!isDelete">
                    <form enctype="multipart/form-data">
                        <div class="btn gray w-100">
                            <input
                                type="file"
                                id="add"
                                class="inputfile"
                                multiple="multiple"
                                accept="image/*"
                                ref="files"
                                @change="onAttachmentChange"
                                style="display: none"/>
                            <label for="add" class="delete_image is_add hstack">
                                <span class="material-symbols-rounded me-2 opacity-50">add_a_photo</span>
                                Добавить фото
                            </label>
                        </div>
                    </form>
                </div>
            </div>
            
            <div class="mb-4">
                <label class="form-label fw-normal mb-1">Примечание</label>
                <textarea class="form-control" v-model="comment"></textarea>
            </div>
            
            <button class="btn btn-green w-100 mt-4 mb-5" @click="save">
                Сохранить
            </button>

        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "detailsBody",
    data: () => ({
        isDelete: false,
        attachment: [],
        file: '',
        files: [],
        showPreview: false,
        imagePreview: '',
        photo: [],
    }),
    props: {
        damage: {
            type: Array,
            default: [],
        },
        wear: {
            type: Array,
            default: [],
        },
        smudges: {
            type: Array,
            default: [],
        },
        image: {
            type: Array,
            default: [],
        },
        comment: '',
        name: '',
        inspectionId: null,
        itemId: null,
        type_inspection: null,
    },
    methods: {
        addDefect(id, item_id, type){
            this.$emit('add-defect', {type_inspection: this.type_inspection, part_id:id, inspection_id: this.inspectionId, type: type, item_id: item_id, comment: this.comment});
        },
        close() {
            this.$emit('close-form')
        },
        fileInfo(file){
            let array = [];
            array.push(file.name);
            array.push(file.type);
            return array;
        },
        onAttachmentChange(event) {
            let uploadedFiles = this.$refs.files.files;
            this.count = 0;
            for (var i = 0; i < uploadedFiles.length; i++) {
                this.files.push(uploadedFiles[i]);
                this.count++;
            }
            this.showPreview = true;
            this.getImagePreviews();
        },
        getImagePreviews() {
            for (let i = 0; i < this.files.length; i++) {
                if (/\.(jpe?g|png|gif)$/i.test(this.files[i].name)) {
                    let reader = new FileReader();
                    reader.readAsDataURL(this.files[i]);

                    reader.onloadend = function() {
                        let result = this.result
                        let img = new Image()
                        img.src = result
                        img.onload = function() {
                            document.getElementById('image-' + parseInt(i)).src = compressImage(img, 0.3);
                            document.getElementById('photo-' + parseInt(i)).value = compressImage(img, 0.3);
                        }
                    }

                }
            }

            function compressImage(img, size) {

                let canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                let initSize = img.src.length
                let width = img.width
                let height = img.height
                canvas.width = width
                canvas.height = height
                ctx.fillStyle = '#fff'
                ctx.fillRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(img, 0, 0, width, height)
                let ndata = canvas.toDataURL('image/jpeg', size)
                return ndata
            }
        },
        save(e) {
            e.preventDefault();
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }, 'content-type': 'multipart/form-data'};
            const formData = new FormData();
            let q = Array.prototype.slice.call(document.getElementsByName("photo"));
            for( let i = 0; i < q.length; i++){
                formData.append('photos[' + i + ']', q[i].defaultValue);
            }
            for (let i = 0; i < this.files.length; i++) {
                formData.append('files[' + i + ']', this.fileInfo(this.files[i]));
            }
            formData.append('id', this.inspectionId);
            formData.append('type', this.type_inspection);
            formData.append('comment', this.comment);
            formData.append('status', 'defect');
            formData.append('slug', 'defect');
            formData.append('item_id', this.itemId);
            axios.post(`/api/admin/tradein/inspection/save`, formData, config)
                .then(response => {
                    if (response.status == 200) {
                        this.close();
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
    }
}
</script>

<style scoped>

</style>
