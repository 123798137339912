<template>
    <div class="bread_crumbs d-flex" v-if="breadCrumbs">
        <div class="bread_crumbs_block d-flex mb-md-3">
            <div class="bread_crumbs_back_link d-flex align-items-center" @click="$router.push(`/${link}`)">
                <svg class="svg" width="12" height="20" viewBox="0 0 12 20"  xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.36308 10L12 17.7781L9.81846 20L0 10L9.81846 0L12 2.22187L4.36308 10Z" fill="#ffffff"/>
                </svg>
                {{ back_title }}
            </div>
            <div class="bread_crumbs_title d-flex justify-content-center align-items-center"
                 :class="!breadCrumbs ? 'main_menu' : ''"
            >{{ title }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BreadCrumbs",
    props: {
        breadCrumbs: false,
        title: '',
        back_title: '',
        link: ''
    },
}
</script>

<style scoped>

</style>
