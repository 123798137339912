<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="this.title"
                :back_title="this.back_title"
                :link="this.link" />

            <div class="page">

                <h5 class="my-3 hstack">
                    <Buying :count="6"/>
                    Внешний осмотр
                </h5>


                <div class="form-check form-switch form-check-inline ps-4">
                    <input class="form-check-input" type="checkbox" @click="notInspect" id="flexSwitchCheckDefault" v-model="check">
                    <label class="form-check-label pt-1 fw-semibold ps-2" for="flexSwitchCheckDefault">Осмотр ЛКП невозможен</label>
                </div>
                <hr>
                <div class="legend">
                    <div class="legend_row hstack fs-7">
                        <span class="material-symbols-rounded me-1" style="color: #4c586d;">help</span> &ndash; не проверено
                    </div>
                    <div class="legend_row hstack fs-7">
                        <span class="material-symbols-rounded me-1" style="color: #00c43c;">check_circle</span> &ndash; проверено, нет дефектов
                    </div>
                    <div class="legend_row hstack fs-7">
                        <span class="material-symbols-rounded me-1" style="color: #cc0000;">error</span> &ndash; проверено, есть дефекты
                    </div>
                </div>
                <hr>

                <!-- Check List -->
                <div class="vstack justify-content-center mt-0">
                    <div v-for="item in array" :key="item.id">
                        <button class="btn cl_item cl_status pad mb-2 w-100 text-center hstack"
                                @click="showFormWheel(item.item_id)"
                                :class="goStatusClass(item.status)"
                                v-if="item.items.slug == 'wheel'"
                        >
                            <span v-if="item.status == 'accept'" class="material-symbols-rounded me-2 opacity-50 cl_status_icon">check_circle</span>
                            <span v-else-if="item.status == 'defect'" class="material-symbols-rounded me-2 opacity-50 cl_status_icon">error</span>
                            <span v-else class="material-symbols-rounded me-2 opacity-50 cl_status_icon">help</span>
                            {{ item.items.name }}
                        </button>
                        <button class="btn cl_item cl_status mb-2 w-100 text-center"
                                @click="showFormNode(item.item_id)"
                                :class="goStatusClass(item.status)"
                                v-else-if="item.items.slug == 'detail'"
                        >
                            <span v-if="item.status == 'accept'" class="material-symbols-rounded me-2 opacity-50 cl_status_icon">check_circle</span>
                            <span v-else-if="item.status == 'defect'" class="material-symbols-rounded me-2 opacity-50 cl_status_icon">error</span>
                            <span v-else class="material-symbols-rounded me-2 opacity-50 cl_status_icon">help</span>
                            {{ item.items.name }}
                        </button>
                        <button class="btn cl_item cl_status mb-2 w-100 text-center"
                                @click="showFormDefect(item.item_id)"
                                :class="goStatusClass(item.status)"
                                v-else-if="item.items.slug == 'windscreen'"
                        >
                            <span v-if="item.status == 'accept'" class="material-symbols-rounded me-2 opacity-50 cl_status_icon">check_circle</span>
                            <span v-else-if="item.status == 'defect'" class="material-symbols-rounded me-2 opacity-50 cl_status_icon">error</span>
                            <span v-else class="material-symbols-rounded me-2 opacity-50 cl_status_icon">help</span>
                            {{ item.items.name }}
                        </button>
                    </div>
                </div>

                <button class="btn btn-green w-100 mt-3 mb-5" @click="saveStep" >Сохранить</button>
            </div>
        </div>
    </transition>

    <transition-group name="list">
        <wheel-damage
            :itemId="itemId"
            :wheels="wheels"
            :inspectionId="inspectionId"
            :image="images"
            :is_save="is_save"
            :comment="comments"
            :type_inspection="type_inspection"
            v-if="isWheelDamage"
            @close-form-wheel="hideFormWheel"
            @add-defect="addDefect($event)"
        />
    </transition-group>

    <transition-group name="list">
        <type-damage
            v-if="isTypeDamage"
            :wheels="wheels"
            :image="images"
            :comment="comments"
            :itemId="itemId"
            :string_name="string_name"
            :ID="ID"
            :inspectionId="inspectionId"
            :type_inspection="type_inspection"
            @close-form-damage="hideFormDamage($event)"
            @add-defect="addDefect($event)"
        />
    </transition-group>

    <transition-group name="list">
        <details-node
            v-if="visible"
            :completes="completes"
            :display="display"
            :factory="factory"
            :type_inspection="type_inspection"
            @add-thickness="addThickness($event)"
            @clear-thickness="clearThickness"
            @add-complete-defect="addCompleteDefect($event)"
            @edit-factory="editFactory($event)"
            @close-form="hideForm"
        />
    </transition-group>
</template>

<script>
import Buying from "./Buying";
import detailsNode from "./detailsNode";
import typeDamage from "./typeDamage";
import WheelDamage from "./wheelDamage";
import axios from "axios";
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
    name: "Step_6",
    components: {
        WheelDamage,
        Buying, detailsNode, typeDamage, spinner, BreadCrumbs
    },
    data: () => ({
        step: 'interior',
        back_step: 'carphoto',
        visible: false,
        isTypeDamage: false,
        isWheelDamage: false,
        check: false,
        outside: [],
        wheels: [],
        status: false,
        itemId: null,
        inspectionId: null,
        isLoading: false,
        images: [],
        comments: '',
        array: [],
        completes: [],
        display: '',
        type_inspection: 'exterior',
        ID: null,
        factory: false,
        string_name: '',
        is_save: false,
        breadCrumbs: true,
        title: "Внешний осмотр",
        back_title: "Этапы приёма",
        link: "",
    }),
    created() {
        this.getData();
        this.getBackLink();
    },
    methods: {
        getBackLink() {
            this.link = this.link = `tradeinlist/${this.$route.params.id}`
        },
        editFactory(event){
            this.factory = !event.factory;
        },
        addCompleteDefect(event){
            this.isLoading = true;
            this.inspectionId = event.inspection_id;
            this.itemId = event.item_id;
            this.type_inspection = event.type_inspection;
            this.ID = event.ID;
            this.getCompleteDamage(event);
            this.isTypeDamage = true;
            setTimeout(() => {
                this.isLoading = false;
            }, 400);
        },
        clearThickness(){
            this.display = '';
        },
        addThickness(event){
            this.display += event.number;
        },
        showFormNode(id){
            this.completes = JSON.parse(JSON.stringify(this.array[id]));
            this.string_name = 'complete';
            this.display = this.completes.data.thickness
            this.factory = this.completes.data.factory
            this.visible = true;
        },
        showFormDefect(id){
            this.wheelID = id,
            this.string_name = 'detail';
            this.getWheelDamage(id, 'detail');
        },
        showFormWheel(id){
            this.wheelID = id
            this.getWheelDamage(id, 'wheel');
        },
        hideForm(){
            this.getData();
            this.visible = false;
        },
        hideFormDamage(event){
            this.getData();
            if(event.type_inspection === 'exterior' && event.string_name === 'complete'){
                this.isLoading = true;
                setTimeout(() => {
                    this.completes = this.array[event.ID];
                    //this.visible = true;
                    this.isTypeDamage = false;
                }, 350);
                this.isLoading = false;
            }
            this.isTypeDamage = false;
        },
        hideFormWheel(){
            this.getData();
            this.isWheelDamage = false;
        },
        notInspect(){
            this.isLoading = true;
            this.check = !this.check
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/inspection/exterior/skip`, {
                check: this.check,
                tradein_id: this.$route.params.id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        setTimeout(() => {
                            this.getData();
                        }, 300);
                    }
                })
                .catch(error =>{
                    console.log(error);
                });
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tradein/inspection/${this.$route.params.id}/exterior`, config);
                this.array = response.data.data.data;
                this.check = response.data.data.check;
                /*this.item_28 = this.array[28]['status'];
                this.item_26 = this.array[26]['status'];
                this.item_16 = this.array[16]['status'];
                this.item_18 = this.array[18]['status'];
                this.item_8 = this.array[8]['status'];
                this.item_22 = this.array[22]['status'];
                this.item_7 = this.array[7]['status'];
                this.item_25 = this.array[25]['status'];
                this.item_35 = this.array[35]['status'];
                this.item_9 = this.array[9]['status'];
                this.item_17 = this.array[17]['status'];
                this.item_27 = this.array[27]['status'];
                this.item_5 = this.array[5]['status'];
                this.item_36 = this.array[36]['status'];
                this.item_19 = this.array[19]['status'];
                this.item_10 = this.array[10]['status'];
                this.item_12 = this.array[12]['status'];
                this.item_31 = this.array[31]['status'];
                this.item_33 = this.array[33]['status'];
                this.item_14 = this.array[14]['status'];
                this.item_30 = this.array[30]['status'];
                this.item_93 = this.array[93]['status'];
                this.item_94 = this.array[94]['status'];
                this.item_95 = this.array[95]['status'];
                this.item_96 = this.array[96]['status'];
                this.item_97 = this.array[97]['status'];
                this.item_98 = this.array[98]['status'];*/
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        async getCompleteDamage(event){
            try {
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tradein/inspection/exterior/checking/${this.$route.params.id}/${event.inspection_id}/${event.item_id}`, config);
                this.wheels = response.data.data.data;
                this.comments = response.data.data.comment;
                this.images = response.data.data.images;
            } catch (e) {
                console.log(e);
            } finally {

            }
        },
        async getWheelDamage(id, type){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tradein/inspection/checking/${this.$route.params.id}/${id}/exterior/${type}`, config);
                this.wheels = response.data.data.data.data;
                this.inspectionId = response.data.data.data.id;
                this.itemId = response.data.data.data.item_id;
                this.comments = response.data.data.comment;
                this.images = response.data.data.images;
                this.is_save = response.data.data.is_save;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
                if(type === 'wheel'){
                    this.isWheelDamage = true;
                }else if(type === 'detail'){
                    this.isTypeDamage = true;
                }

            }
        },
        addDefect(event){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/inspection/add/checking`, {
                part_id: event.part_id,
                item_id: event.item_id,
                inspection_id: event.inspection_id,
                type: event.type,
                detail: event.detail,
                type_inspection: event.type_inspection,
                comment: event.comment,
                slug: 'defect',
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        if(event.type_inspection === 'exterior'){
                            this.getCompleteDamage(event)
                        }else {
                            this.getWheelDamage(event.item_id);
                        }
                    }
                })
                .catch(error =>{
                    console.log(error);
                });
        },
        goClass(status){
            if(status === 'not_verified'){
                return '#808080';
            }else if(status === 'accept'){
                return '#338833';
            }else if(status === 'defect'){
                return '#CC0000';
            }else if(status === 'skip'){
                return '#CFC700';
            }

        },
        goStatusClass(status) {
            return `type_${status}`;
        },
        saveStep(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/inspection/save/step`, {
                id: this.$route.params.id,
                step_number: 6,
                type: 'exterior',
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.$router.push(`/buying/interior/${this.$route.params.id}`);
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>

</style>


