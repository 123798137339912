<template>
    <span class="header_steps_count">{{ count }} / 11</span>
</template>

<script>
    import {getTradeInStatusStep} from "./../../services/helpers";
    export default {
        name: "Buying",
        props: {
            count: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {lists: []}
        },
        created() {
            this.getStepLists();
        },
        methods: {
            async getStepLists() {
                this.lists = await getTradeInStatusStep(this.$route.params.id);
            }
        }
    }
</script>


<style scoped>

</style>
