<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="block_select">
                    <div class="item_title">
                        <Form ref="car" @submit="createCheck">
                            <div class="block_car_number">
                                <Field type="text" style="text-transform: uppercase;"
                                       placeholder="Номер или VIN авто"
                                       v-model="car.car_number"
                                       class="form-control"
                                       name="car_number"
                                />
                                <ErrorMessage name="car_number" style="color: red;" class="mt-3"/>
                                <div class="mt-4 mb-2 text-center">
                                    <button class="btn btn-red col-12">ДАЛЕЕ</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import {ErrorMessage, Field, Form} from "vee-validate";
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "CarNumberPage",
    components: {
        spinner, Field, Form, ErrorMessage, BreadCrumbs
    },
    data() {
        return {
            isLoading: false,
            car: {
                car_number: '',
            },
            errors: {
                car_number: '',
            },
            breadCrumbs: true,
            title: 'Номер автомобиля',
            back_title: 'Список чек листов',
            link: 'car',
        }
    },
    methods: {
        createCheck() {
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
            axios.post('/api/admin/car/create', {
                check_id: this.$route.params.check_id,
                car_number: this.car.car_number,
            }, config)
                .then(response => {
                    if (response.status == 200) {

                        this.$router.push(`/car/${this.$route.params.check_id}/${response.data.data.car_id}`);
                    }
                })
                .catch(errors => {
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.car.setErrors(errors.response.data.errors);
                            this.errors.car_number = errors.response.data.errors.car_number ?? 0;
                            break;
                    }
                });
        },
    }
}
</script>

<style scoped>

</style>
