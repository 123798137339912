<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                
                <h5 class="py-2">Выдача аванса</h5>

                <div class="item_title mb-2">

                    <!-- Номер, Дата -->
                    <div
                        class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                        <div class="fs-6 fw-bold">№{{ id }}</div>
                        <div>{{ moment(created_at).format("DD.MM.YYYY hh:mm") }}</div>
                    </div>

                    <!-- Сотрудник -->
                    <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                        <span class="fw-normal">{{ $t("request.worker") }}</span>
                        <span class="text-end fw-semibold">{{ fullname }}</span>
                    </div>

                    <!-- Сумма -->
                    <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                        <span class="fw-normal">{{ $t("request.amount") }}</span>
                        <span class="text-end fw-semibold">{{ cur(payment_amount) }}</span>
                    </div>

                    <!-- Долг -->
                    <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                        <span class="fw-normal">{{ $t("request.debt") }}</span>
                        <span class="text-end fw-semibold">{{ cur(debt) }}</span>
                    </div>

                    <!-- Заработано -->
                    <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                        <span class="fw-normal">{{ $t("request.earned") }}</span>
                        <span class="text-end fw-semibold">{{ cur(earned) }}</span>
                    </div>

                    <!-- Способ  -->
                    <div class="hstack justify-content-between border-bottom pb-2 mb-2">
                        <span class="fw-normal">{{ $t("request.method_obtaining") }}</span>
                        <span class="text-end fw-semibold">{{ payment_type }}</span>
                    </div>

                </div>

                <div class="mb-3 mt-3">
                    <label class="form-label">{{ $t('request.comment') }}</label>
                    <textarea class="form-control" rows="3" v-model="comment"></textarea>
                </div>
                <div class="mb-2 mt-4">
                    <button class="btn btn_vw btn-green" @click="save">
                        <span class="material-symbols-rounded opacity-50 me-1">check</span>
                        {{ $t('request.button.imprest_complete') }}
                    </button>
                </div>
                <div class="mb-2 mt-2">
                    <button class="btn btn_vw gray" @click="getHistory(list.historys)">
                        <span class="material-symbols-rounded opacity-50 me-1">history</span>
                        {{ $t('request.button.history') }}
                    </button>
                </div>
                   
                
            </div>
        </div>
    </transition>

    <transition-group name="list">
        <history
            v-if="is_history"
            :historis="historis"
            @close-form="close"
        />
    </transition-group>
</template>

<script>
import History from "./History";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import axios from "axios";
import moment from "moment";

export default {
    name: "ImprestCashierPageCompleted",
    components: {
        History,
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect
    },
    data(){
        return {
            is_history: false,
            historis: [],
            is_modified: false,
            errors: {
                comment: '',
                modified_amount: '',
            },
            url: '',
            id: null,
            fullname: null,
            payment_amount: null,
            debt: null,
            earned: null,
            payment_type: null,
            comment: null,
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.request')+ ' №' + this.$route.params.id,
            back_title: this.$t('request.list_requests'),
            link: 'request/imprest/cashier/list/work',
        }
    },
    created(){
        this.getData();
    },
    methods: {
        moment,
        close() {
            this.is_history = false;
        },
        getHistory(data) {
            this.historis = data;
            this.is_history = true;
        },
        save(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};

            axios.post('/api/admin/imprest/cashier/work/save', {
                id: this.id,
                comment: this.comment,
            }, config)
                .then(response => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.$router.push('/request/imprest/cashier/list/work')
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/imprest/one/${this.$route.params.id}`, config);
                this.list = response.data.data.list;
                this.id = this.list.id;
                this.debt = this.list.debt;
                this.earned = this.list.earned;
                this.fullname = this.list.user.surname +' '+ this.list.user.name;
                this.payment_amount = this.list.payment_amount;
                this.payment_type = this.list.payment_type;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        downloadURL(url){
            let count=0;
            let hiddenIFrameID = 'hiddenDownloader' + count++;
            var iframe = document.createElement('iframe');
            iframe.id = hiddenIFrameID;
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            iframe.src = url;
        },
        async downloadImprest(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/imprest/download/${this.$route.params.id}`, config);
                this.url = response.data.data.url;
                this.downloadURL(this.url);
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        cur(val) {
            if (!val) {
                return "0 KZT";
            }
            const format = new Intl.NumberFormat("ru-Ru", {
                style: "currency",
                currency: "KZT",
                minimumFractionDigits: 0,
            });
            return format.format(parseInt(val));
        },
    }
}
</script>

<style scoped>

</style>
