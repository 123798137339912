<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page d-flex flex-column justify-content-center mt-0">
                <div class="item_title">
                    {{ checkName }}
                </div>
                <div class="block_item">
                    <div class="mb-2" v-for="checklist in subitems" :key="checklist.id">
                        <div class="cl_item little" @click="next(checklist.id)" :class="goClass(checklist.status_check)">
                            {{ checklist.name }}
                        </div>
                    </div>
                    <template v-if="!btn_is_load">
                        <button class="btn btn-green mt-4 btn_vw mb-4" :disabled="active" :class="{ active: active }" @click="save">СОХРАНИТЬ</button>
                    </template>
                    <template v-else>
                        <button class="btn gray w-100" disabled="disabled">
                            <span class="spinner-border spinner-border-sm me-2"></span>
                            Сохранение...
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "TSMSubItemsPage",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            subitems: [],
            itemName: '',
            active: false,
            breadCrumbs: true,
            title: 'Пункты проверок',
            back_title: 'Список вопросов',
            link: 'tsm/item',
            btn_is_load: false,
        }
    },
    created() {
        this.getChecklists();
    },
    methods: {
        save(){
            this.btn_is_load = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/tsm/save', {
                item_id: this.$route.params.item_id,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.$router.push('/tsm/item')
                        this.btn_is_load = false;
                    }
                })
                .catch(error => {
                    this.btn_is_load = false;
                    console.log(error);
                });
        },
        next(id) {
            this.$router.push(`/tsm/item/${this.$route.params.item_id}/${id}`);
        },
        async getChecklists() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/tsm/get/item/${this.$route.params.item_id}`, config);
                this.subitems = response.data.data.subitems;
                this.checkName = response.data.data.itemName;
                this.active = response.data.data.status;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        goClass(status_check) {
            return `type_${status_check}`;
        },
    }
}
</script>

<style scoped>

</style>
