<template>
    <div class="popup">
        <div class="popup-content">
            <div class="close float-end" @click="close">
                <span class="material-symbols-rounded">close</span>
            </div>

            <h5 class="mb-4">{{ completes.data.list[0].name }}</h5>
            <h6 class="mt-2 hstack">
                <span class="material-symbols-rounded me-2 opacity-50">straighten</span>
                Показания толщиномера
            </h6>
            <div class="pb-2 mb-2 border-bottom">
                <label class="form-label">Если деталь не требует замера, оставьте поле пустым.</label>
                <input type="tel" class="form-control" v-model="display"/>
                <div class="form-check form-check-inline ps-4">
                    <input class="form-check-input" type="checkbox" id="switchLKP" @click="addLkp"/>
                    <label class="form-check-label pt-1 fw-semibold ps-2" for="switchLKP">
                        <span>ЛКП не заводское</span>
                    </label>
                </div>
            </div>

            <h6 class="mt-4 hstack">
                <span class="material-symbols-rounded me-2 opacity-50">rule</span>
                Повреждённые детали
            </h6>
            <div class="form-label fs-7 fw-normal mb-3 ms-0">
                По-умолчанию все детали имеют статус «без повреждений». 
                При наличии дефекта, выберите деталь и укажите характер повреждения.
            </div>
            <div class="vstack justify-content-center mt-0">
                <div
                    v-for="item in completes.data.list"
                    :key="item.id"
                    @click="addDefect(item.id)">
                    <button
                        class="btn cl_item pad mb-2 btn_vw text-center"
                        :class="goStatus(item.status)">
                        {{ item.name }}
                    </button>
                </div>
            </div>

            <button class="btn btn-green w-100 mb-5 mt-3" @click="save">Сохранить</button>

        </div>
    </div>
</template>

<script>

import axios from "axios";

export default {
  name: "detailsNode",
  data() {
    return {};
  },
  props: {
    completes: {
      type: Array,
      default: [],
    },
    display: "",
    type_inspection: null,
    factory: false,
  },
  methods: {
    addLkp() {
      this.$emit("edit-factory", { factory: this.factory });
    },
    addDefect(id) {
      this.$emit("add-complete-defect", {
        item_id: id,
        inspection_id: this.completes.id,
        type_inspection: this.type_inspection,
        ID: this.completes.item_id,
      });
    },
    // add(number){
    //     this.$emit('add-thickness', {number: number});
    // },
    // clear(){
    //     this.$emit('clear-thickness');
    // },
    close() {
      this.$emit("close-form");
    },
    goStatus(status) {
      return `accessories_` + status;
    },
    save(e) {
      e.preventDefault();
      const config = {
        headers: { Authorization: "Bearer " + this.$cookie.getCookie("token") },
      };
      axios
        .post(
          "/api/admin/tradein/inspection/exterior/save",
          {
            inspection_id: this.completes.id,
            type_inspection: this.type_inspection,
            thickness: this.display,
            lkp: this.lkp,
            factory: this.factory,
          },
          config
        )
        .then((response) => {
          if (response.status == 200) {
            this.close();
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>

<style scoped></style>
