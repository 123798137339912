import {createWebHistory, createRouter} from "vue-router";
import Login from "../Auth/Login";
import MainPage from "../Page/MainPage";
import * as auth from '../services/auth';
import tradein from "./tradein";
import tsm from "./tsm";
import testdrive from "./testdrive";
import request from "./requests/request";
import admins from "./admins/admins";
import issuance from "./issuance";
import inspections from "./inspections";
import boards from "./boards";
import notifications from "../Page/Notifications.vue";
import versions from "../components/Versions.vue";
import Email from "../Auth/Email";
import Reset from "../Auth/Reset";
import AfterSend from "../Auth/AfterSend.vue";

export const routes = [
    {
        name: 'login',
        path: '/',
        component: Login,
        beforeEnter(to, from, next) {
            if (auth.isLoggedIn()) {
                next('/main')
            } else {
                next();
            }
        },
    },
    {
        name: 'main',
        path: '/main',
        component: MainPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/');
            } else {
                next();
            }
        },
    },
    {
        name: 'notifications',
        path: '/notifications/:active',
        component: notifications,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/');
            } else {
                next();
            }
        }
    },
    {
        name: 'versions',
        path: '/versions',
        component: versions,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/');
            } else {
                next();
            }
        }
    },
    ...admins,
    ...tradein,
    ...tsm,
    ...testdrive,
    ...request,
    ...issuance,
    ...inspections,
    ...boards,
    {
        name: 'Email',
        path: '/reset-password',
        component: Email,
    },
    {
        name: 'Reset',
        path: '/reset-password/:token',
        component: Reset,
    },
    {
        name: 'AfterSend',
        path: '/reset-password/sended',
        component: AfterSend
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior (to, from, savedPosition) {
        return {left: 0, top: 0}
    }
});

export default router;
