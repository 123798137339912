import './bootstrap'
import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap';

import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'
import  App from './App.vue';
import store from './store';
import mitt from 'mitt';

import "./plugins/vee-validate";
import axios from 'axios';
import router from './routes';
import { VueCookieNext } from 'vue-cookie-next';
import Maska from 'maska';
import VueSignature from "vue-signature-pad";
import VueApexCharts from "vue3-apexcharts";
import print from 'vue3-print-nb'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas)
library.add(far)

import translations from './translations/index';
const i18n = createI18n({
    locale: 'ru',
    fallbackLocale: 'en',
    messages: translations
})

import VueProgressBar from "@aacassandra/vue3-progressbar";
const pb_options = {
    color: "#ee0000",
    failedColor: "#4c586d",
    thickness: "3px",
    transition: {
      speed: "0.7s",
      opacity: "0.1s",
      termination: 700,
    },
    autoRevert: false,
    location: "top",
    inverse: false,
  };

const eventBus = mitt();
const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$store = store;
app.config.globalProperties.eventBus = eventBus;
app.use(VueProgressBar, pb_options);
app.use(router);
app.use(VueCookieNext);
app.use(Maska);
app.use(VueSignature);
app.use(VueApexCharts);
app.use(print);
app.use(i18n);

VueCookieNext.config({ expire: '1y' })

const config = {'headers': {'Authorization': 'Bearer ' + VueCookieNext.getCookie('token')}};
app
    .component('fa', FontAwesomeIcon)
    .component('pad', VueSignature)
    .mount('#app');
app.provide('config', config);
