<template>
    <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Список прав</h5>
                    <button type="button" class="btn-close close_modal" @click="close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md">
                            <table class="table table-hover">
                                <thead class="table-light">
                                    <tr>
                                        <th>Название</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="permission in all_permissions" :key="permission.id">
                                        <td style="width: 90%;">{{ permission.title }}</td>
                                        <td class="text-center">
                                            <label class="cb-container">
                                                <input
                                                    name="menu"
                                                    type="checkbox"
                                                    class="check-highload"
                                                    v-model="perms"
                                                    :id="permission.id"
                                                    :value="permission.id"/>
                                                    <span class="highload2"></span>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn gray" @click="close">Закрыть</button>
                    <button type="button" class="btn btn-green" @click="savePermission">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddPermission",
    data(){
        return {
            perms: [],
        }
    },
    props: {
        all_permissions: {
            type: Array,
            default: [],
        },
        moduleId: null,
    },
    methods: {
        close(){
            this.$emit('close-add-permission')
        },
        savePermission(){
            this.$emit('save-permission', {moduleId: this.moduleId, perms: this.perms})
        }
    }
}
</script>

<style scoped>

</style>
