<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="this.title"
                :back_title="this.back_title"
                :link="this.link"/>

            <div class="page">
                <h5 class="my-3 hstack">
                    <Buying :count="3"/> Юридическая чистота
                </h5>
                <div class="fs-7 fw-normal mb-3">Данные запрашиваются из сторонних сервисов. В связи с этим возможна задержка ответа.</div>
                <!-- Утильсбор -->
                <div class="item_title mb-3">
                    <div class="fw-semibold mb-2 fs-6 hstack">
                        <span class="material-symbols-rounded me-2 p-1 rounded-circle ic-icon" :class="getStatus(list.util.status)">recycling</span>
                        <span>Утилизационный сбор</span>
                    </div>

                    <template v-if="list.util.status === 'new'">
                        <template v-if="!btn_is_load_util">
                            <button :disabled="is_util" class="btn gray w-100" @click="getUtil()">Запросить</button>
                        </template>
                        <template v-else>
                            <button class="btn gray w-100" disabled="disabled">
                                <span class="spinner-border spinner-border-sm me-2"></span>
                                Загрузка...
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <div v-if="list.util.found" class="fw-normal hstack">
                            <span class="material-symbols-rounded me-1 text-success pb-1">done_all</span>
                            <span>{{ list.util.message }}</span>
                        </div>
                        <div v-else>
                            <div class="fw-normal hstack mb-2">
                                <span class="material-symbols-rounded me-1 text-danger pb-1">close</span>
                                <span>{{ list.util.message }}</span>
                            </div>
                            <template v-if="!btn_is_load_util">
                                <button :disabled="is_util" class="btn gray w-100" @click="getUtil()">Запросить повторно</button>
                            </template>
                            <template v-else>
                                <button class="btn gray w-100" disabled="disabled">
                                    <span class="spinner-border spinner-border-sm me-2"></span>
                                    Загрузка...
                                </button>
                            </template>
                        </div>
                    </template>
                </div>

                <!-- История регистрации -->
                <div class="item_title mb-3">
                    <div class="fw-semibold mb-2 fs-6 hstack">
                        <span class="material-symbols-rounded me-2 p-1 rounded-circle ic-icon" :class="getStatus(list.history.status)">history</span>
                        <span>История регистрации (РФ)</span>
                    </div>
                    <template v-if="list.history.status == 'new'">
                        <template v-if="!btn_is_load">
                            <button :disabled="is_history" class="btn gray w-100" @click="getHistory()">Запросить</button>
                        </template>
                        <template v-else>
                            <button class="btn gray w-100" disabled="disabled">
                                <span class="spinner-border spinner-border-sm me-2"></span>
                                Загрузка...
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="list.history.found === 200">
                            <div class="mb-1 pb-1 border-bottom">
                                <div class="form-label m-0 fw-normal pb-1">Марка/Модель</div>
                                <div class="">{{ list.history.model }}</div>
                            </div>
                            <div class="mb-1 pb-1 border-bottom">
                                <div class="form-label m-0 fw-normal pb-1">Год выпуска</div>
                                <div class="">{{ list.history.year }}</div>
                            </div>
                            <div class="mb-1 pb-1 border-bottom">
                                <div class="form-label m-0 fw-normal pb-1">Идентификационный номер (VIN)</div>
                                <div class="">{{ list.history.vin }}</div>
                            </div>
                            <div class="mb-1 pb-1 border-bottom">
                                <div class="form-label m-0 fw-normal pb-1">Номер шасси (рамы)</div>
                                <div class="">{{ list.history.chassisNumber }}</div>
                            </div>
                            <div class="mb-1 pb-1 border-bottom">
                                <div class="form-label m-0 fw-normal pb-1">Номер кузова</div>
                                <div class="">{{ list.history.bodyNumber }}</div>
                            </div>
                            <div class="mb-1 pb-1 border-bottom">
                                <div class="form-label m-0 fw-normal pb-1">Цвет кузова</div>
                                <div class="">{{ list.history.color }}</div>
                            </div>
                            <div class="mb-1 pb-1 border-bottom">
                                <div class="form-label m-0 fw-normal pb-1">Номер двигателя</div>
                                <div class="">{{ list.history.engineNumber }}</div>
                            </div>
                            <div class="mb-1 pb-1 border-bottom">
                                <div class="form-label m-0 fw-normal pb-1">Рабочий объем, см³</div>
                                <div class="">{{ list.history.engineVolume }}</div>
                            </div>
                            <div class="mb-1 pb-1 border-bottom">
                                <div class="form-label m-0 fw-normal pb-1">Мощность, кВт/л.с.</div>
                                <div class="">{{ list.history.powerKwt }}/{{ list.history.powerHp }}</div>
                            </div>
                            <div class="mb-1 pb-1 border-bottom">
                                <div class="form-label m-0 fw-normal pb-1">Периоды владения</div>
                                <div class="" v-for="item in list.history.from">
                                    {{ item.from }} - {{ item.to }}
                                </div>
                            </div>
                        </template>
                        <template v-else-if="list.history.found === 503">
                            <div class="fw-normal hstack mb-3">
                                <span class="material-symbols-rounded me-1 text-danger pb-1">close</span>
                                <span>{{ list.history.message }}</span>
                            </div>
                            <template v-if="!btn_is_load">
                                <button :disabled="is_history" class="btn gray w-100" @click="getHistory()">Запросить повторно</button>
                            </template>
                            <template v-else>
                                <button class="btn gray w-100" disabled="disabled">
                                    <span class="spinner-border spinner-border-sm me-2"></span>
                                    Загрузка...
                                </button>
                            </template>
                        </template>
                        <template v-else-if="list.history.found === 201">
                            <div class="fw-normal hstack mb-3">
                                <span class="material-symbols-rounded me-1 text-danger pb-1">close</span>
                                <span>{{ list.history.message }}</span>
                            </div>
                            <template v-if="!btn_is_load">
                                <button :disabled="is_history" class="btn gray w-100" @click="getHistory()">Запросить повторно</button>
                            </template>
                            <template v-else>
                                <button class="btn gray w-100" disabled="disabled">
                                    <span class="spinner-border spinner-border-sm me-2"></span>
                                    Загрузка...
                                </button>
                            </template>
                        </template>
                        <template v-else-if="list.history.found === 404">
                            <div class="fw-normal hstack mb-3">
                                <span class="material-symbols-rounded me-1 text-success pb-1">done</span>
                                <span>{{ list.history.message }}</span>
                            </div>
                        </template>
                    </template>
                </div>

                <!-- Участие в ДТП -->
                <div class="item_title mb-3">
                    <div class="fw-semibold mb-2 fs-6 hstack">
                        <span class="material-symbols-rounded me-2 p-1 rounded-circle ic-icon" :class="getStatus(list.aiusdtp.status)">car_crash</span>
                        <span>Участие в ДТП (РФ)</span>
                    </div>

                    <template v-if="list.aiusdtp.status == 'new'">
                        <template v-if="!btn_is_load_dtp">
                            <button :disabled="is_dtp" class="btn gray w-100" @click="getDtp()">Запросить</button>
                        </template>
                        <template v-else>
                            <button class="btn gray w-100" disabled="disabled">
                                <span class="spinner-border spinner-border-sm me-2"></span>
                                Загрузка...
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="list.aiusdtp.found === 201">
                            <div class="fw-normal hstack mb-3">
                                <span class="material-symbols-rounded me-1 text-danger pb-1">close</span>
                                <span>{{ list.aiusdtp.message }}</span>
                            </div>
                            <template v-if="!btn_is_load_dtp">
                                <button :disabled="is_dtp" class="btn gray w-100" @click="getDtp()">Запросить повторно</button>
                            </template>
                            <template v-else>
                                <button class="btn gray w-100" disabled="disabled">
                                    <span class="spinner-border spinner-border-sm me-2"></span>
                                    Загрузка...
                                </button>
                            </template>
                        </template>
                        <template v-else-if="list.aiusdtp.found === 503">
                            <div class="fw-normal hstack mb-3">
                                <span class="material-symbols-rounded me-1 text-danger pb-1">close</span>
                                <span>{{ list.aiusdtp.message }}</span>
                            </div>
                            <template v-if="!btn_is_load_dtp">
                                <button :disabled="is_dtp" class="btn gray w-100" @click="getDtp()">Запросить повторно</button>
                            </template>
                            <template v-else>
                                <button class="btn gray w-100" disabled="disabled">
                                    <span class="spinner-border spinner-border-sm me-2"></span>
                                    Загрузка...
                                </button>
                            </template>
                        </template>
                        <template v-else-if="list.aiusdtp.found === 200">
                            <template v-if="list.aiusdtp.records.length">
                                <div
                                    v-for="(item, index) in list.aiusdtp.records"
                                    :key="index"
                                    class="dtp border-start ps-3 my-4 border-danger"
                                    style="border-width: 2px !important">
                                    <div class="mb-1 pb-1 border-bottom">
                                        <div class="form-label m-0 fw-normal pb-1">Дата и время</div>
                                        <div class="">{{ item.data }}</div>
                                    </div>
                                    <div class="mb-1 pb-1 border-bottom">
                                        <div class="form-label m-0 fw-normal pb-1">Тип происшествия</div>
                                        <div class="">{{ item.type }}</div>
                                    </div>
                                    <div class="mb-1 pb-1 border-bottom">
                                        <div class="form-label m-0 fw-normal pb-1">Место происшествия</div>
                                        <div class="">{{ item.place }}</div>
                                    </div>
                                    <div class="mb-1 pb-1">
                                        <div class="form-label m-0 fw-normal pb-1">Повреждения</div>
                                        <div class=""></div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="fw-normal hstack mb-3">
                                    <span class="material-symbols-rounded me-1 text-success pb-1">done</span>
                                    <span>{{ list.aiusdtp.message }}</span>
                                </div>
                            </template>
                        </template>
                    </template>
                </div>

                <!-- Розыск -->
                <div class="item_title mb-3">
                    <div class="fw-semibold mb-2 fs-6 hstack">
                        <span class="material-symbols-rounded me-2 p-1 rounded-circle ic-icon" :class="getStatus(list.wanted.status)">policy</span>
                        <span>Нахождение в розыске (РФ)</span>
                    </div>
                    <template v-if="list.wanted.status == 'new'">
                        <template v-if="!btn_is_load_wanted">
                            <button :disabled="is_wanted" class="btn gray w-100" @click="getWanted()">Запросить</button>
                        </template>
                        <template v-else>
                            <button class="btn gray w-100" disabled="disabled">
                                <span class="spinner-border spinner-border-sm me-2"></span>
                                Загрузка...
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="list.wanted.found === 201">
                            <div class="fw-normal hstack mb-3">
                                <span class="material-symbols-rounded me-1 text-danger pb-1">close</span>
                                <span>{{ list.wanted.message }}</span>
                            </div>
                            <template v-if="!btn_is_load_wanted">
                                <button :disabled="is_wanted" class="btn gray w-100" @click="getWanted()">Запросить повторно</button>
                            </template>
                            <template v-else>
                                <button class="btn gray w-100" disabled="disabled">
                                    <span class="spinner-border spinner-border-sm me-2"></span>
                                    Загрузка...
                                </button>
                            </template>
                        </template>
                        <template v-else-if="list.wanted.found === 503">
                            <div class="fw-normal hstack mb-3">
                                <span class="material-symbols-rounded me-1 text-danger pb-1">close</span>
                                <span>{{ list.wanted.message }}</span>
                            </div>
                            <template v-if="!btn_is_load_wanted">
                                <button :disabled="is_wanted" class="btn gray w-100" @click="getWanted()">Запросить повторно</button>
                            </template>
                            <template v-else>
                                <button class="btn gray w-100" disabled="disabled">
                                    <span class="spinner-border spinner-border-sm me-2"></span>
                                    Загрузка...
                                </button>
                            </template>
                        </template>
                        <template v-else-if="list.wanted.found === 200">
                            <template v-if="list.wanted.records.length > 0">
                                <template v-for="(item, index) in list.wanted.records" :key="index">
                                    <div class="mb-1 pb-1 border-bottom">
                                        <div class="form-label m-0 fw-normal pb-1">В розыске</div>
                                        <div class="">с {{ item.data }} г.</div>
                                    </div>
                                    <div class="mb-1 pb-1 border-bottom">
                                        <div class="form-label m-0 fw-normal pb-1">Регион</div>
                                        <div class="">{{ item.region }}</div>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="fw-normal hstack mb-3">
                                    <span class="material-symbols-rounded me-1 text-success pb-1">done</span>
                                    <span>{{ list.wanted.message }}</span>
                                </div>
                            </template>
                        </template>
                    </template>
                </div>

                <!-- Ограничения -->
                <div class="item_title mb-3">
                    <div class="fw-semibold mb-2 fs-6 hstack">
                        <span class="material-symbols-rounded me-2 p-1 rounded-circle ic-icon" :class="getStatus(list.restricted.status)">front_hand</span>
                        <span>Наличие ограничений (РФ)</span>
                    </div>
                    <template v-if="list.restricted.status == 'new'">
                        <template v-if="!btn_is_load_restricted">
                            <button :disabled="is_restricted" class="btn gray w-100" @click="getRestricted()">Запросить</button>
                        </template>
                        <template v-else>
                            <button class="btn gray w-100" disabled="disabled">
                                <span class="spinner-border spinner-border-sm me-2"></span>
                                Загрузка...
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="list.restricted.found === 201">
                            <div class="fw-normal hstack mb-3">
                                <span class="material-symbols-rounded me-1 text-danger pb-1">close</span>
                                <span>{{ list.restricted.message }}</span>
                            </div>
                            <template v-if="!btn_is_load_restricted">
                                <button :disabled="is_restricted" class="btn gray w-100" @click="getRestricted()">Запросить повторно</button>
                            </template>
                            <template v-else>
                                <button class="btn gray w-100" disabled="disabled">
                                    <span class="spinner-border spinner-border-sm me-2"></span>
                                    Загрузка...
                                </button>
                            </template>
                        </template>
                        <template v-else-if="list.restricted.found === 503">
                            <div class="fw-normal hstack mb-3">
                                <span class="material-symbols-rounded me-1 text-danger pb-1">close</span>
                                <span>{{ list.restricted.message }}</span>
                            </div>
                            <template v-if="!btn_is_load_restricted">
                                <button :disabled="is_restricted" class="btn gray w-100" @click="getRestricted()">Запросить повторно</button>
                            </template>
                            <template v-else>
                                <button class="btn gray w-100" disabled="disabled">
                                    <span class="spinner-border spinner-border-sm me-2"></span>
                                    Загрузка...
                                </button>
                            </template>
                        </template>
                        <template v-else-if="list.restricted.found === 200">
                            <template v-if="list.restricted.records.length > 0">
                                <template v-for="(item, index) in list.restricted.records" :key="index">
                                    {{ item }}
                                </template>
                            </template>
                            <template v-else>
                                <div class="fw-normal hstack mb-3">
                                    <span class="material-symbols-rounded me-1 text-success pb-1">done</span>
                                    <span>{{ list.restricted.message }}</span>
                                </div>
                            </template>
                        </template>
                    </template>
                </div>

                <!-- Диагностика -->
                <div class="item_title mb-3">
                    <div class="fw-semibold mb-2 fs-6 hstack">
                        <span class="material-symbols-rounded me-2 p-1 rounded-circle ic-icon" :class="getStatus(list.diagnostics.status)">fact_check</span>
                        <span>Наличие диагност. карты (РФ)</span>
                    </div>
                    <template v-if="list.diagnostics.status == 'new'">
                        <template v-if="!btn_is_load_diagnostics">
                            <button :disabled="is_diagnostics" class="btn gray w-100" @click="getDiagnostics()">Запросить</button>
                        </template>
                        <template v-else>
                            <button class="btn gray w-100" disabled="disabled">
                                <span class="spinner-border spinner-border-sm me-2"></span>
                                Загрузка...
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="list.diagnostics.found === 201">
                            <div class="fw-normal hstack mb-3">
                                <span class="material-symbols-rounded me-1 text-danger pb-1">close</span>
                                <span>{{ list.diagnostics.message }}</span>
                            </div>
                            <template v-if="!btn_is_load_diagnostics">
                                <button :disabled="is_diagnostics" class="btn gray w-100" @click="getDiagnostics()">Запросить повторно</button>
                            </template>
                            <template v-else>
                                <button class="btn gray w-100" disabled="disabled">
                                    <span class="spinner-border spinner-border-sm me-2"></span>
                                    Загрузка...
                                </button>
                            </template>
                        </template>
                        <template v-else-if="list.diagnostics.found === 503">
                            <div class="fw-normal hstack mb-3">
                                <span class="material-symbols-rounded me-1 text-danger pb-1">close</span>
                                <span>{{ list.diagnostics.message }}</span>
                            </div>
                            <template v-if="!btn_is_load_diagnostics">
                                <button :disabled="is_diagnostics" class="btn gray w-100" @click="getDiagnostics()">Запросить повторно</button>
                            </template>
                            <template v-else>
                                <button class="btn gray w-100" disabled="disabled">
                                    <span class="spinner-border spinner-border-sm me-2"></span>
                                    Загрузка...
                                </button>
                            </template>
                        </template>
                        <template v-else-if="list.diagnostics.found === 200">
                            <template v-if="list.diagnostics.records.length > 0">
                                <template v-for="(item, index) in list.diagnostics.records" :key="index">
                                    {{ item }}
                                </template>
                            </template>
                            <template v-else>
                                <div class="fw-normal hstack mb-3">
                                    <span class="material-symbols-rounded me-1 text-success pb-1">done</span>
                                    <span>{{ list.diagnostics.message }}</span>
                                </div>
                            </template>
                        </template>
                    </template>
                </div>

                <div class="mt-4 mb-5">
                    <button class="btn btn-green w-100" @click="saveStep">
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Buying from "./Buying";
import axios from "axios";
import {getTradeInStatusStep} from "../../services/helpers";
import spinner from "../../components/Spinner";
import {delay, method} from "lodash";
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
    name: "Step_3",
    components: {
        Buying,
        spinner,
        BreadCrumbs,
    },
    data: () => ({
        isLoading: false,
        step: "service",
        back_step: "car",
        vin_code: "",
        car_number: "",
        is_util: false,
        is_history: false,
        is_dtp: false,
        is_restricted: false,
        is_wanted: false,
        is_diagnostics: false,
        list: {
            util: '',
            history: '',
            aiusdtp: '',
            wanted: '',
            restricted: '',
            diagnostics: '',
        },
        is_save: null,
        breadCrumbs: true,
        title: "Юр. чистота",
        back_title: "Этапы приёма",
        link: "",
        btn_is_load: false,
        btn_is_load_util: false,
        btn_is_load_dtp: false,
        btn_is_load_wanted: false,
        btn_is_load_restricted: false,
        btn_is_load_diagnostics: false,
    }),
    created() {
        this.getData();
    },
    mounted() {
        this.getStepLists();
        this.getBackLink();
    },
    methods: {
        async getUtil() {
            this.btn_is_load_util = true;
            this.is_history = true;
            this.is_dtp = true;
            this.is_restricted = true;
            this.is_wanted = true;
            this.is_diagnostics = true;
            const config = {headers: {Authorization: "Bearer " + this.$cookie.getCookie("token")},};
            const response = await axios.get(`/api/admin/tradein/purity/util/${this.$route.params.id}`, config);
            this.list = response.data.data.list;
            this.btn_is_load_util = false;
            this.is_history = false;
            this.is_dtp = false;
            this.is_restricted = false;
            this.is_wanted = false;
            this.is_diagnostics = false;
        },
        async getHistory() {
            this.btn_is_load = true;
            this.is_util = true;
            this.is_dtp = true;
            this.is_restricted = true;
            this.is_wanted = true;
            this.is_diagnostics = true;
            const config = {headers: {Authorization: "Bearer " + this.$cookie.getCookie("token")},};
            const response = await axios.get(`/api/admin/tradein/purity/history/${this.$route.params.id}`, config);
            this.list = response.data.data.list;
            this.btn_is_load = false;
            this.is_util = false;
            this.is_dtp = false;
            this.is_restricted = false;
            this.is_wanted = false;
            this.is_diagnostics = false;
        },
        async getDtp() {
            this.btn_is_load_dtp = true;
            this.is_util = true;
            this.is_history = true;
            this.is_restricted = true;
            this.is_wanted = true;
            this.is_diagnostics = true;
            const config = {headers: {Authorization: "Bearer " + this.$cookie.getCookie("token")},};
            const response = await axios.get(`/api/admin/tradein/purity/aiusdtp/${this.$route.params.id}`, config);
            this.list = response.data.data.list;
            this.btn_is_load_dtp = false;
            this.is_util = false;
            this.is_history = false;
            this.is_restricted = false;
            this.is_wanted = false;
            this.is_diagnostics = false;
        },
        async getWanted() {
            this.btn_is_load_wanted = true;
            this.is_util = true;
            this.is_history = true;
            this.is_restricted = true;
            this.is_dtp = true;
            this.is_diagnostics = true;
            const config = {headers: {Authorization: "Bearer " + this.$cookie.getCookie("token")},};
            const response = await axios.get(`/api/admin/tradein/purity/wanted/${this.$route.params.id}`, config);
            this.list = response.data.data.list;
            this.btn_is_load_wanted = false;
            this.is_util = false;
            this.is_history = false;
            this.is_restricted = false;
            this.is_dtp = false;
            this.is_diagnostics = false;
        },
        async getRestricted() {
            this.btn_is_load_restricted = true;
            this.is_util = true;
            this.is_history = true;
            this.is_restricted = true;
            this.is_wanted = true;
            this.is_diagnostics = true;
            const config = {headers: {Authorization: "Bearer " + this.$cookie.getCookie("token")},};
            const response = await axios.get(`/api/admin/tradein/purity/restricted/${this.$route.params.id}`, config);
            this.list = response.data.data.list;
            this.btn_is_load_restricted = false;
            this.is_util = false;
            this.is_history = false;
            this.is_restricted = false;
            this.is_wanted = false;
            this.is_diagnostics = false;
        },
        async getDiagnostics() {
            this.btn_is_load_diagnostics = true;
            this.is_util = true;
            this.is_history = true;
            this.is_restricted = true;
            this.is_wanted = true;
            this.is_diagnostics = true;
            const config = {headers: {Authorization: "Bearer " + this.$cookie.getCookie("token")},};
            const response = await axios.get(`/api/admin/tradein/purity/diagnostics/${this.$route.params.id}`, config);
            this.list = response.data.data.list;
            this.btn_is_load_diagnostics = false;
            this.is_util = false;
            this.is_history = false;
            this.is_restricted = false;
            this.is_wanted = false;
            this.is_diagnostics = false;
        },
        getBackLink() {
            this.link = this.link = `tradeinlist/${this.$route.params.id}`;
        },
        saveStep() {
            this.isLoading = true;
            const config = {headers: {Authorization: "Bearer " + this.$cookie.getCookie("token")},};
            axios.post(`/api/admin/tradein/purity/save/step`, {
                id: this.$route.params.id,
                step_number: 3,
            }, config)
                .then((response) => {
                    if (response.status == 200) {
                        this.$router.push(`/buying/service/${this.$route.params.id}`);
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        getStatus(status) {
            if (status == 'new') {
                return 'ic-gray';
            } else if (status == 'defect') {
                return 'ic-red fa-fade';
            } else if (status == 'accept') {
                return 'ic-green';
            } else if (status == 'warning') {
                return 'ic-yellow';
            }
        },
        async getStepLists() {
            this.lists = await getTradeInStatusStep(this.$route.params.id);
        },
        async getData() {
            try {
                this.isLoading = true;
                const config = {headers: {Authorization: "Bearer " + this.$cookie.getCookie("token")},};
                const response = await axios.get(`/api/admin/tradein/purity/${this.$route.params.id}`, config);
                this.list = response.data.data.list;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped="scoped"></style>
