<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <div class="d-flex justify-between flex-wrap my-4">
                        <div class="col-12 col-sm-4 col-lg-3 offset-lg-3">
                            <button class="btn gray btn_add w-100"
                                @click="showModal"
                            >Добавить</button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive-md" v-if="this.lists.length > 0">
                    <table  class="table table-hover">
                        <thead>
                        <tr>
                            <th class="text-muted">Организация</th>
                            <th class="text-muted">Название вопроса</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(list, index) in lists"
                            :key="list.id"
                        >
                            <td>
                                {{ list.organization.name }}
                            </td>
                            <td>
                                {{ list.name }}
                            </td>
                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button
                                        class="btn btn_drop px-0"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        id="dropdownMenuButton">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#ccc"
                                            class="bi bi-three-dots-vertical"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul
                                        class="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuButton">
                                        <li>
                                            <button
                                                @click="addSubQuestion(list, index)"
                                                type="button"
                                                class="dropdown-item">Пункты осмотра</button>
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider">
                                        </li>
                                        <li>
                                            <button
                                                @click="editQuestion(list)"
                                                type="button"
                                                class="dropdown-item">Редактировать</button>
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider"></li>
                                        <li>
                                            <button
                                                type="button"
                                                @click="deleteQuestion(list.id)"
                                                class="dropdown-item">Удалить</button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive-md" v-else>
                    <h3>Данных нет!</h3>
                </div>
            </div>
        </div>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="is_items_modal"
            @close="closeItemsModal"
        >
            <template v-slot:title>
                Список вопросов осмотра
            </template>
            <template v-slot:body>
                <div class="gap-3 hstack justify-content-between mb-2">
                    <button class="btn w-100 gray px-2" @click="addItem(items.id, 'question')">
                        Добавить вопрос
                    </button>
                </div>
                <div class="table-responsive-md" v-if="this.items.children.length > 0">
                    <table  class="table table-hover">
                        <thead>
                        <tr>
                            <th class="text-muted">Название вопроса</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(list, idx) in items.children"
                            :key="list.id"
                        >

                            <td>
                                {{ list.name }}
                            </td>
                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button
                                        class="btn btn_drop px-0"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        id="dropdownItemsButton">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#ccc"
                                            class="bi bi-three-dots-vertical"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul
                                        class="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownItemsButton">
                                        <li>
                                            <button
                                                @click="addDefectModel(list, idx)"
                                                type="button"
                                                class="dropdown-item">Дефекты</button>
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider">
                                        </li>
                                        <li>
                                            <button
                                                @click="editQuestion(list)"
                                                type="button"
                                                class="dropdown-item">Редактировать</button>
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider"></li>
                                        <li>
                                            <button
                                                type="button"
                                                @click="deleteQuestion(list.id)"
                                                class="dropdown-item">Удалить</button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive-md" v-else>
                    <h3>Данных нет!</h3>
                </div>
            </template>
        </Modal>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="is_defect_modal"
            @close="closeDefectModal"
        >
            <template v-slot:title>
                Список пунктов дефектов
            </template>
            <template v-slot:body>
                <div class="gap-3 hstack justify-content-between mb-2">
                    <button class="btn w-100 gray px-2" @click="addItem(defects.id, 'defect')">
                        Добавить пункт дефекта
                    </button>
                </div>
                <div class="table-responsive-md" v-if="this.defects.children.length > 0">
                    <table  class="table table-hover">
                        <thead>
                        <tr>
                            <th class="text-muted">Название дефектв</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(list, index) in defects.children" :key="list.id">
                            <td>{{ list.name }}</td>
                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button
                                        class="btn btn_drop px-0"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        id="dropdownDefectButton">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#ccc"
                                            class="bi bi-three-dots-vertical"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownDefectButton">
                                        <li><hr class="dropdown-divider"></li>
                                        <li>
                                            <button
                                                @click="editQuestion(list)"
                                                type="button"
                                                class="dropdown-item">Редактировать</button>
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider"></li>
                                        <li>
                                            <button
                                                type="button"
                                                @click="deleteQuestion(list.id)"
                                                class="dropdown-item">Удалить</button>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive-md" v-else>
                    <h3>Данных нет!</h3>
                </div>
            </template>
        </Modal>
    </transition>

    <transition name="slide-fade">
        <Modal
            v-if="is_modal"
            @close="closeModal"
        >
            <template v-slot:title>
                {{ form_name }}
            </template>
            <template v-slot:body>
                <div class="row mb-3 mt-3">
                    <label class="col-sm-12 col-form-label required text-start">Организация</label>
                    <div class="col-sm position-relative">
                        <VueMultiselect
                            v-model="organization"
                            placeholder="Выберите организацию"
                            label="name"
                            track-by="id"
                            :options="organizations"
                            :show-labels="false"
                        ></VueMultiselect>
                        <span v-if="errors.organization.length > 0" role="alert" class="mt-3">{{ errors.organization }}</span>
                    </div>
                </div>

                <div class="row mb-3 mt-3">
                    <label class="col-sm-12 col-form-label required text-start">{{ input_name }}</label>
                    <div class="col-sm position-relative">
                        <Field
                            v-model="name"
                            type="text"
                            name="name"
                            class="form-control"
                            :class="[errors.name.length > 0 ? 'is_valid' : '']"
                        />
                        <span v-if="errors.name.length > 0" role="alert" class="mt-3">{{ errors.name }}</span>
                    </div>
                </div>
                <div class="col-sm position-relative mb-2 mt-2">
                    <button class="btn btn_vw btn-green"
                            @click="save"
                    >
                        Сохранить
                    </button>
                </div>
            </template>
        </Modal>
    </transition>

    <transition name="slide-fade">
        <toast-alert
            v-if="is_alert"
            @close_alert="closeAlert"
            :message="message"
            :type="type"
        />
    </transition>
</template>

<script>
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ToastAlert from "../../components/ToastAlert";
import axios from "axios";
import {ErrorMessage, Field, Form} from "vee-validate";
import Modal from '../../components/Modal';
import VueMultiselect from "vue-multiselect";

export default {
    name: "InspectionQuestions",
    components: {
        spinner, BreadCrumbs, ToastAlert, Field, Form, ErrorMessage, Modal, VueMultiselect
    },
    data(){
        return {
            lists: [],
            items: [],
            defects: [],
            organizations: [],
            organization: null,
            form_name: '',
            input_name: '',
            name: '',
            type: '',
            id: null,
            parent_id: null,
            index: null,
            idx: null,
            errors: {
                name: '',
                organization: '',
            },
            message: '',
            is_alert: false,
            is_modal: false,
            is_items_modal: false,
            is_defect_modal: false,
            isLoading: false,
            breadCrumbs: true,
            title: 'Вопросы осмотра',
            back_title: 'Осмотр ТС',
            link: 'inspections',
        }
    },
    created() {
        this.getData();
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        showModal(){
            this.form_name = 'Добавить новый этап осмотра';
            this.input_name = 'Название этапа';
            this.is_modal = true;
        },
        closeModal(){
            this.id = null,
            this.name = '';
            this.organization = null
            this.is_modal = false;
            this.errors.name = '';
        },
        closeItemsModal(){
            this.parent_id = null
            this.is_items_modal = false;
        },
        closeDefectModal(){
            this.parent_id = null
            this.is_defect_modal = false;
        },
        editQuestion(list){
            this.id = list.id;
            this.name = list.name;
            this.organization = list.organization;
            this.is_modal = true;
        },
        addItem(id, type){
            this.parent_id = id;
            if(type === 'defect'){
                this.input_name = 'Название дефекта';
                this.form_name = 'Добавить дефект';
            }
            if(type === 'question'){
                this.form_name = 'Добавить вопрос';
                this.input_name = 'Название вопроса';
            }
            this.is_modal = true;
        },
        addDefectModel(list, idx){
            this.defects = list
            this.idx = idx
            this.is_defect_modal = true;
        },
        addSubQuestion(list, index){
            this.items = list
            this.index = index
            this.organization = list.organization;
            this.is_items_modal = true;
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/question', config);
                this.lists = response.data.data.lists;
                this.organizations = response.data.data.organizations;
                if(this.is_items_modal){
                    this.items = this.lists[this.index];
                }
                if(this.is_defect_modal){
                    this.defects = this.lists[this.index]['children'][this.idx];
                }
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        deleteQuestion(id){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/question/delete', {
                id: id,
            }, config)
                .then(response => {
                    if(response.status == 200){
                        this.is_modal = false;
                        this.is_alert = true;
                        this.message = 'Успешно удалено';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.name = null;
                        this.id = null
                        this.parent_id = null
                        this.getData();
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
        },
        save() {
            if (this.name.length > 0 && this.organization) {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                axios.post('/api/admin/question', {
                    name: this.name,
                    id: this.id,
                    parent_id: this.parent_id,
                    organization: this.organization,
                }, config)
                    .then(response => {
                        if (response.status == 200) {
                            this.is_modal = false;
                            this.is_alert = true;
                            this.type = 'green';
                            this.message = 'Успешно сохранено';
                            setTimeout(() => { this.closeAlert(); }, 3000);
                            this.name = '';
                            this.id = null;
                            this.parent_id = null;
                            this.getData();
                        }
                    })
                    .catch(errors => {
                        console.log(errors);
                    });
            } else {
                this.errors.name = 'Поле название вопроса обязательно!';
                this.errors.organization = 'Поле организация обязательно!';
            }
        },
    }
}
</script>

<style scoped>

</style>
