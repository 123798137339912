<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row">
                    <div class="col-md">
                        <div class="row g-2">
                            <div class="col-md-6 mb-4">
                                <VueMultiselect
                                    v-model="category"
                                    :placeholder="$t('request.select_category')"
                                    label="name"
                                    track-by="id"
                                    :options="categories"
                                    :taggable="true"
                                    :show-labels="false"
                                >
                                </VueMultiselect>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <router-link to="/request/settings/documents/create">
                            <button class="btn gray btn_add">
                                {{ $t('request.button.add') }}
                            </button>
                        </router-link>
                    </div>
                </div>
                <div class="table-responsive-md">
                    <table class="table table-row table-app">
                        <thead>
                        <tr>
                            <th class="text-muted">Организация</th>
                            <th class="text-muted">{{ $t('request.category') }}</th>
                            <th class="text-muted">{{ $t('request.document_name') }}</th>
                            <th class="text-muted">{{ $t('request.list_responsible_persons') }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="document in filteredDocuments"
                            :key="document.id"
                        >
                            <td>
                                {{ document.category.organization.name }}
                            </td>

                            <td>
                                {{ document.category.name }}
                            </td>
                            <td>
                                {{ document.name }}
                            </td>

                            <td>
                                <div
                                    v-for="(item, idx) in document.data"
                                >
                                    <div>{{ idx+1 }}. {{ item.fullname }}</div>
                                </div>
                            </td>

                            <td class="text-end" style="width: 5%;">
                                <div class="dropdown">
                                    <button class="btn btn_drop px-0" type="button" data-bs-toggle="dropdown" id="dropdownMenuButton">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                        <li><button type="button" @click="$router.push(`/request/settings/documents/edit/${document.id}`)" class="dropdown-item">{{ $t('request.button.edit') }}</button></li>
<!--                                        <li><hr class="dropdown-divider"></li>
                                        <li>
                                            <button type="button" @click="deleteItem(document.id)" class="dropdown-item">{{ $t('request.button.delete') }}</button>
                                        </li>-->
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import Modules from "../../../Page/Modules";
import {ErrorMessage, Field, Form} from "vee-validate";
import axios from "axios";
import VueMultiselect from "vue-multiselect";

export default {
    name: "Documents",
    components: {
        spinner, BreadCrumbs, Modules, Field, Form, ErrorMessage, VueMultiselect
    },
    data() {
        return {
            documents: [],
            categories: [],
            category: null,
            isLoading: false,
            breadCrumbs: true,
            title: this.$t('request.routes'),
            back_title: this.$t('request.settings'),
            link: 'request/settings',
        }
    },
    computed: {
        filteredDocuments: function() {
            return this.documents.filter(function(item) {
                if(this.category !== null){
                    return item.category_id == this.category.id;
                }else{
                    return this.documents;
                }
            }.bind(this));
        },
    },
    created(){
        this.getData();
    },
    methods: {
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/settings/documents/index', config);
                this.documents = response.data.data.documents;
                this.categories = response.data.data.categories;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        deleteItem(){

        }
    }
}
</script>

<style scoped>

</style>
