import * as auth from '../../services/auth';
import MemosPage from "../../Modules/Request/Memos/MemosPage";
import MemosCreate from "../../Modules/Request/Memos/MemosCreate";
import MemosIndex from "../../Modules/Request/Memos/MemosIndex";
import MemosChiefList from "../../Modules/Request/Memos/MemosChiefList";
import MemosJournal from "../../Modules/Request/Memos/MemosJournal";

export default [
    {
        name: 'MemosPage',
        path: '/request/memos',
        component: MemosPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'MemosCreate',
        path: '/request/memos/create',
        component: MemosCreate,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'MemosIndex',
        path: '/request/memos/index',
        component: MemosIndex,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'MemosChiefList',
        path: '/request/memos/chief/list',
        component: MemosChiefList,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'MemosJournal',
        path: '/request/memos/journal',
        component: MemosJournal,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
];
