<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="this.title"
                :back_title="this.back_title"
                :link="this.link" />
            <div class="page">
                <h5 class="my-3 hstack">
                    <Buying :count="8"/>
                    Подкапотное пространство
                </h5>
                <hr>
                <div class="legend">
                    <div class="legend_row hstack fs-7">
                        <span class="material-symbols-rounded me-1" style="color: #4c586d;">help</span> &ndash; не проверено
                    </div>
                    <div class="legend_row hstack fs-7">
                        <span class="material-symbols-rounded me-1" style="color: #00c43c;">check_circle</span> &ndash; проверено, нет дефектов
                    </div>
                    <div class="legend_row hstack fs-7">
                        <span class="material-symbols-rounded me-1" style="color: #cc0000;">error</span> &ndash; проверено, есть дефекты
                    </div>
                </div>
                <hr>
                <!-- Check List -->
                <div class="vstack justify-content-center mt-0">
                    <div v-for="item in array" :key="item.id">
                        <button class="btn cl_item cl_status pad mb-2 w-100 text-center hstack"
                            @click="addChecking(item.item_id)"
                            :class="goStatusClass(item.status)">
                            <span v-if="item.status == 'accept'" class="material-symbols-rounded me-2 opacity-50 cl_status_icon">check_circle</span>
                            <span v-else-if="item.status == 'defect'" class="material-symbols-rounded me-2 opacity-50 cl_status_icon">error</span>
                            <span v-else class="material-symbols-rounded me-2 opacity-50 cl_status_icon">help</span>
                            {{ item.items.name }}
                        </button>
                    </div>
                </div>
                <button class="btn btn-green w-100 mt-3 mb-5" @click="saveStep" >Сохранить</button>
            </div>
        </div>
    </transition>

    <transition-group name="list">
        <details-body
            v-if="isVisible"
            :wear="wear"
            :itemId="itemId"
            :damage="damage"
            :smudges="smudges"
            :image="images"
            :name="name"
            :type_inspection="'hood-space'"
            :inspectionId="inspectionId"
            :comment="comments"
            @close-form="hideFormBody"
            @add-defect="addDefect($event)"
        />
    </transition-group>

</template>

<script>
import Buying from "./Buying";
import HoodSpace from "./HoodSpace";
import axios from "axios";
import spinner from "../../components/Spinner";
import DetailsBody from "./detailsBody";
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
    name: "Step_8",
    components: {
        HoodSpace,
        Buying,
        DetailsBody,
        spinner,
        BreadCrumbs

    },
    data: () => ({
        step: 'complete',
        back_step: 'interior',
        isVisible: false,
        item_42: null,
        item_43: null,
        item_44: null,
        item_45: null,
        item_46: null,
        array: [],
        parts: [],
        isLoading: false,
        images: [],
        comments: '',
        name: '',
        car: [],
        wear: [],
        damage: [],
        smudges: [],
        inspectionId: null,
        itemId: null,
        breadCrumbs: true,
        title: "Подкапотное пространство",
        back_title: "Этапы приёма",
        link: "",
    }),
    created() {
        this.getData();
        this.getBackLink();
    },
    methods: {
        getBackLink() {
            this.link = this.link = `tradeinlist/${this.$route.params.id}`
        },
        saveStep(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/inspection/save/step`, {
                id: this.$route.params.id,
                step_number: 8,
                type: 'hood-space',
            }, config)

                .then(response =>{
                    if(response.status == 200){
                        /* this.$flashMessage.show({
                             type: 'success',
                             text: 'Пункт меню успешно удален!',
                         });*/
                        this.$router.push(`/buying/complete/${this.$route.params.id}`);
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        addDefect(event){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/inspection/add/checking`, {
                part_id: event.part_id,
                inspection_id: event.inspection_id,
                type: event.type,
                item_id: event.item_id,
                type_inspection: event.type_inspection,
                comment: event.comment,
                slug: 'defect',
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.getChecking(event.item_id);
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tradein/inspection/${this.$route.params.id}/hood-space`, config);
                this.array = response.data.data.data;
                this.item_42 = this.array[42]['status'];
                this.item_43 = this.array[43]['status'];
                this.item_44 = this.array[44]['status'];
                this.item_45 = this.array[45]['status'];
                this.item_46 = this.array[46]['status'];
                this.car = response.data.data.car;
                this.isLoading = false;
            } catch (e) {
                console.log(e);
            } finally {
            }
        },
        async getChecking(id){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tradein/inspection/checking/${this.$route.params.id}/${id}/hood-space/null`, config);
                this.parts = response.data.data.data;
                this.wear = this.parts.data.wear;
                this.damage = this.parts.data.damage;
                this.smudges = this.parts.data.smudges;
                this.inspectionId = this.parts.id;
                this.itemId = this.parts.item_id;
                this.name = this.parts.items.name;
                this.images = response.data.data.images;
                this.comments = response.data.data.comment;
                this.isLoading = false;
                this.isLoading = false;
            } catch (e) {
                console.log(e);
            } finally {
            }
        },
        addChecking(id){
            this.getChecking(id);
            this.isVisible = true;
        },
        hideFormBody(){
            this.getData();
            this.isVisible = false;
        },
        goClass(status){
            if(status === 'not_verified'){
                return '#808080';
            }else if(status === 'accept'){
                return '#338833';
            }else if(status === 'defect'){
                return '#CC0000';
            }
        },
        goStatusClass(status) {
            return `type_${status}`;
        },
    }
}
</script>

<style scoped>

</style>
