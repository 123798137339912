<template>

    <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">ПОИСК ДОГОВОРА</h5>
                    <button type="button" class="btn-close close_modal" @click="close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md">
                            <div class="time d-flex col-12 mb-3 vstack">
                                <span class="form-label ms-0">Время выдачи</span>
                                <span class="hstack">
                                    <span class="icon_clock"><fa icon="clock" /></span>
                                    <span class="d-block h5 mb-0">{{position[0]}} - {{position[1]}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" placeholder="Номер договора..." aria-label="Номер договора" aria-describedby="button-addon2" v-model="contractNum" @keyup.enter.prevent="search">
                                <div class="input-group-append" @click="search">
                                    <button class="btn btn-secondary" type="submit" v-if="!cFind">Найти</button>
                                    <button class="btn btn-secondary" type="submit" v-if="cFind" disabled>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <div class="col-12" v-if="contract">
                                <div class="d-flex col-12 mb-3"><span class="icon_car"><fa icon="car" /></span><span class="d-block"><strong>{{contract.car}}</strong><br><span class="vin">{{contract.vin}}</span></span></div>
                                <div class="border-0 client col-12 ml-3"><span class="icon_user"><fa icon="user" /></span> <strong>{{contract.client}}</strong></div>
                            </div>

                            <div class="form-group mt-4" v-if="contract">
                                <textarea v-model="comment" class="form-control" rows="3" placeholder="Примечание (если требуется)"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="d-flex hstack gap-3 col-12">
                        <button type="button" class="btn gray w-100" @click="close">Закрыть</button>
                        <button type="button" class="btn btn-green w-100" @click="create">Добавить</button>                    
                    </div>
                </div>
            </div>
        </div>
    </div>

    <toast-alert
        v-if="is_alert"
        @close_alert="closeAlert"
        :message="message"
        :type="type"
    />
</template>

<script>
import axios from "axios";
import ToastAlert from "../../components/ToastAlert";

export default {
    name: "CreateModal",
    components: {
        ToastAlert
    },
    props: {
        data: Object,
        date:String
    },
    data() {
        return {
            is_alert: false,
            message: "",
            type: "",
            position: this.data.position,
            positionNum: this.data.positionNum,
            contractNum: "",
            contract: null,
            cFind: false,
            comment: "",
            loading: false
        }
    },
    methods: {
        closeAlert(){
            this.is_alert = false;
        },
        create() {
            this.$emit('created_issuance', {
                contract: this.contract,
                comment: this.comment,
                position: this.positionNum,
                date: this.date
            });
        },
        async search() {
            try {
                this.cFind = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/issuances/contract/search/${this.contractNum}`, config);
                this.contract = response.data.data.contract;
                if(!response.data.data.is_found) {
                    this.is_alert = true;
                    this.type = response.data.data.type;
                    this.message = response.data.data.message;
                    setTimeout(() => {this.closeAlert(); }, 2000);
                }
            } catch (e) {
                console.log(e);
            }finally {
                this.cFind = false
            }
        },
        close() {
            this.$emit('close-create-modal')
        },
    }
}
</script>

<style scoped>
.time {
    font-size: 21px;
    color: #777;
}

.icon_clock {
    font-size: 14px;
    padding: 5px 10px 0 0!important;
    color: #999;
}

.vin {
    font-size: 14px;
    color: #777;
    font-weight: 400;
}

.icon_car {
    color: #999;
    padding: 3px 10px 0 0!important;
}

.icon_user {
    color: #999;
    padding: 5px 10px 0 0!important;
}
</style>
