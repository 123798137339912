import * as auth from '../services/auth';
import Quality from "../Modules/TSM/Quality/Quality";
import CheckList from "../Modules/TSM/Quality/CheckList";
import IndexCheck from "../Modules/TSM/Quality/IndexCheck";
import CreateCheck from "../Modules/TSM/Quality/CreateCheck";
import CheckPage from "../Modules/TSM/Quality/CheckPage";
import IncongruityPage from "../Modules/TSM/Quality/IncongruityPage";
import IncongruityDefectPage from "../Modules/TSM/Quality/IncongruityDefectPage";
import SelectCheck from "../Modules/TSM/SelectCheck";
import EditCheck from "../Modules/TSM/Quality/EditCheck";
import ReportPage from "../Modules/TSM/Quality/ReportPage";
import ReportEditPage from "../Modules/TSM/Quality/ReportEditPage";
import SettingsCheckPage from "../Modules/TSM/SettingsCheckPage";
import CarCheckListPage from "../Modules/TSM/Car/CarCheckListPage";
import CarNumberPage from "../Modules/TSM/Car/CarNumberPage";
import CarSubcheckList from "../Modules/TSM/Car/CarSubcheckList";
import CarCheckingPage from "../Modules/TSM/Car/CarCheckingPage";
import SelectIncongruity from "../Modules/TSM/SelectIncongruity";
import CarDefectsLists from "../Modules/TSM/Car/CarDefectsLists";
import TSMIndexCheck from "../Modules/TSM/tsm/TSMIndexCheck";
import TSMCreateCheck from "../Modules/TSM/tsm/TSMCreateCheck";
import TSMEditCheck from "../Modules/TSM/tsm/TSMEditCheck";
import TSMItemPage from "../Modules/TSM/tsm/TSMItemPage";
import TSMSubItemsPage from "../Modules/TSM/tsm/TSMSubItemsPage";
import TSMCheckingPage from "../Modules/TSM/tsm/TSMCheckingPage";
import TSMDefectsListPage from "../Modules/TSM/tsm/TSMDefectsListPage";
import TSMDefectsPage from "../Modules/TSM/tsm/TSMDefectsPage";
import TSMCorrectionPage from "../Modules/TSM/tsm/TSMCorrectionPage";
import CarDefectsPage from "../Modules/TSM/Car/CarDefectsPage";
import CarCorrectionPage from "../Modules/TSM/Car/CarCorrectionPage";
import CarIndexCheck from "../Modules/TSM/Car/CarIndexCheck";
import CarCreateCheck from "../Modules/TSM/Car/CarCreateCheck";
import CarEditCheck from "../Modules/TSM/Car/CarEditCheck";
import TSMPage from "../Modules/TSM/TSMPage";
import DefectsLists from "../Modules/TSM/Boss/DefectsLists";
import DefectsPage from "../Modules/TSM/Boss/DefectsPage";
import DefectsUserLists from "../Modules/TSM/Boss/DefectsUserLists";
import Penalties from "../Modules/TSM/Quality/Penalties";
import IncongruityListPage from "../Modules/TSM/Quality/IncongruityListPage";

export default [
    {
        name: 'tsm',
        path: '/tsm',
        component: TSMPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'SelectCheck',
        path: '/check-list',
        component: SelectCheck ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'Quality',
        path: '/quality',
        component: Quality ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CheckList',
        path: '/quality/:dep_id',
        component: CheckList ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CheckPage',
        path: '/quality/:dep_id/:check_id',
        component: CheckPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'SettingsCheckPage',
        path: '/check-setting',
        component: SettingsCheckPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'IndexCheck',
        path: '/quality-setting',
        component: IndexCheck ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CreateCheck',
        path: '/quality-setting/create',
        component: CreateCheck ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'EditCheck',
        path: '/quality-setting/edit/:id',
        component: EditCheck ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'SelectIncongruity',
        path: '/select-defects',
        component: SelectIncongruity ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CarDefectsLists',
        path: '/car-defects',
        component: CarDefectsLists ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CarDefectsPage',
        path: '/car-defects/:car_id',
        component: CarDefectsPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CarCorrectionPage',
        path: '/car-defects/:car_id/:check_id',
        component: CarCorrectionPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'IncongruityListPage',
        path: '/incongruity',
        component: IncongruityListPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'IncongruityPage',
        path: '/incongruity/:dep_id',
        component: IncongruityPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'IncongruityDefectPage',
        path: '/incongruity/:dep_id/:check_id',
        component: IncongruityDefectPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ReportPage',
        path: '/report',
        component: ReportPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ReportEditPage',
        path: '/report/edit/:id',
        component: ReportEditPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CarCheckListPage',
        path: '/car',
        component: CarCheckListPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CarNumberPage',
        path: '/car/:check_id',
        component: CarNumberPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CarSubcheckList',
        path: '/car/:check_id/:car_id',
        component: CarSubcheckList ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CarCheckingPage',
        path: '/car/:check_id/:car_id/:subcheck_id',
        component: CarCheckingPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TSMIndexCheck',
        path: '/tsm-setting',
        component: TSMIndexCheck ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TSMCreateCheck',
        path: '/tsm-setting/create',
        component: TSMCreateCheck ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TSMEditCheck',
        path: '/tsm-setting/edit/:id',
        component: TSMEditCheck ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TSMItemPage',
        path: '/tsm/item',
        component: TSMItemPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TSMSubItemsPage',
        path: '/tsm/item/:item_id',
        component: TSMSubItemsPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TSMCheckingPage',
        path: '/tsm/item/:item_id/:subitem_id',
        component: TSMCheckingPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TSMDefectsListPage',
        path: '/tsm-defects',
        component: TSMDefectsListPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TSMDefectsPage',
        path: '/tsm-defects/:item_id',
        component: TSMDefectsPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'TSMCorrectionPage',
        path: '/tsm-defects/:item_id/:check_id',
        component: TSMCorrectionPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CarIndexCheck',
        path: '/car-setting',
        component: CarIndexCheck ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CarCreateCheck',
        path: '/car-setting/create',
        component: CarCreateCheck ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CarEditCheck',
        path: '/car-setting/edit/:id',
        component: CarEditCheck ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'defects',
        path: '/defects',
        component: DefectsPage ,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'defectsLists',
        path: '/defects/:name',
        component: DefectsLists,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'defectsUserLists',
        path: '/defects/:name/:user_id',
        component: DefectsUserLists,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'Penalties',
        path: '/penalties',
        component: Penalties,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
]
