<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page d-flex flex-column justify-content-center mt-0">
                <div class="block_select">
                    <div class="mt-2 mb-2 text-center">
                        <router-link class="btn btn-red btn_vw" to="/defects/cars">
                            Дефекты Автомойка<span class="badge rounded-pill text-bg-white ms-2" v-if="cars > 0">{{ cars }}</span>
                        </router-link>
                    </div>
                    <div class="mt-2 mb-2 text-center">
                        <router-link class="btn btn-red btn_vw" to="/defects/tsm">
                            Дефекты TSM <span class="badge rounded-pill text-bg-white ms-2" v-if="tsm > 0">{{ tsm }}</span>
                        </router-link>
                    </div>
                    <div class="mt-2 mb-2 text-center">
                        <router-link class="btn btn-red btn_vw" to="/defects/quality">
                            Дефекты ежедневные <span class="badge rounded-pill text-bg-white ms-2" v-if="quality > 0">{{ quality }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import axios from "axios";

export default {
    name: "DefectsPage",
    components: {
        spinner, BreadCrumbs
    },
    data() {
        return {
            cars: 0,
            tsm: 0,
            quality: 0,
            isLoading: true,
            breadCrumbs: true,
            title: 'Дефекты',
            back_title: 'Чек листы TSM',
            link: 'tsm',
        }
    },
    created() {
        this.getAllDefects();
    },
    methods: {
        async getAllDefects() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get('/api/admin/tsm/defects/count', config);
                this.cars = response.data.data.cars;
                this.tsm = response.data.data.tsm;
                this.quality = response.data.data.quality;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>
