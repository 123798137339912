import * as auth from "../../services/auth";
import PaymentsPage from "../../Modules/Request/Payments/PaymentsPage";
import PaymentsCreate from "../../Modules/Request/Payments/PaymentsCreate";
import MemosIndex from "../../Modules/Request/Memos/MemosIndex";
import PaymentsIndex from "../../Modules/Request/Payments/PaymentsIndex";
import PaymentChiefList from "../../Modules/Request/Payments/PaymentChiefList";
import PaymentsJournal from "../../Modules/Request/Payments/PaymentsJournal";
import PaymentsEdit from "../../Modules/Request/Payments/PaymentsEdit";


export default [
    {
        name: 'PaymentsPage',
        path: '/request/payments',
        component: PaymentsPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'PaymentsIndex',
        path: '/request/payments/index',
        component: PaymentsIndex,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'PaymentsCreate',
        path: '/request/payments/create',
        component: PaymentsCreate,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'PaymentsEdit',
        path: '/request/payments/edit/:id',
        component: PaymentsEdit,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'PaymentChiefList',
        path: '/request/payments/chief/list',
        component: PaymentChiefList,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'PaymentsJournal',
        path: '/request/payments/journal',
        component: PaymentsJournal,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
]
