<template>
    <div class="loader">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>    
</template>
<script>
    export default {
        name: "spinner",
    }
</script>
