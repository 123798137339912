import * as auth from "../services/auth";
import Issuance from "../Modules/Issuance/Issuance";
import IssuancePage from "../Modules/Issuance/IssuancePage";
import Settings from "../Modules/Issuance/Settings/Settings";
import ChecklistManager from "../Modules/Issuance/ChecklistManager";
import CheckPoint from "../Modules/Issuance/Settings/CheckPoint";
import ChecklistChief from "../Modules/Issuance/ChecklistChief";
import CheckListSb from "../Modules/Issuance/CheckListSb";
import CheckPageSb from "../Modules/Issuance/CheckPageSb";
import ChecklistReception from "../Modules/Issuance/ChecklistReception";
import CheckPageManager from "../Modules/Issuance/CheckPageManager";
import CheckPageChief from "../Modules/Issuance/CheckPageChief";
import CheckListCredit from "../Modules/Issuance/CheckListCredit";
import SettingsIssuancePage from "../Modules/Issuance/Settings/SettingsIssuancePage";
import CheckListAdmin from "../Modules/Issuance/CheckListAdmin";
import AuthorizedEmployees from "../Modules/Issuance/Settings/AuthorizedEmployees.vue";
import ChecklistDirector from "../Modules/Issuance/ChecklistDirector.vue";

export default [
    {
        name: 'IssuancePage',
        path: '/issuance',
        component: IssuancePage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'Issuance',
        path: '/issuance/index',
        component: Issuance,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'SettingsIssuancePage',
        path: '/issuance/settings',
        component: SettingsIssuancePage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'Settings',
        path: '/issuance/settings/workers',
        component: Settings,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CheckPoint',
        path: '/issuance/settings/points',
        component: CheckPoint,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'AuthorizedEmployees',
        path: '/issuance/settings/authorized',
        component: AuthorizedEmployees,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ChecklistManager',
        path: '/issuance/manager',
        component: ChecklistManager,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CheckPageManager',
        path: '/issuance/manager/:issue_id',
        component: CheckPageManager,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ChecklistChief',
        path: '/issuance/chief',
        component: ChecklistChief,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CheckPageChief',
        path: '/issuance/chief/:issue_id',
        component: CheckPageChief,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CheckListCredit',
        path: '/issuance/credit',
        component: CheckListCredit,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CheckListSb',
        path: '/issuance/sb',
        component: CheckListSb,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CheckPageSb',
        path: '/issuance/sb/checklist/:issue_id',
        component: CheckPageSb,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ChecklistReception',
        path: '/issuance/reception',
        component: ChecklistReception,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'CheckListAdmin',
        path: '/issuance/admin',
        component: CheckListAdmin,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        name: 'ChecklistDirector',
        path: '/issuance/director',
        component: ChecklistDirector,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
];
