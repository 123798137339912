<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <h3 class="mb-3">Несоответствия</h3>
                <div class="block_select">
                    <div class="mt-2 mb-2 text-center">
                        <router-link class="btn btn-red pad btn_vw" to="/car-defects">
                            Автомойка <span class="badge rounded-pill text-bg-white ms-2" v-if="car > 0">{{ car }}</span>
                        </router-link>
                    </div>
                    <div class="mt-2 mb-2 text-center">
                        <router-link class="btn btn-red pad btn_vw" to="/tsm-defects">
                            TSM <span class="badge rounded-pill text-bg-white ms-2" v-if="tsm > 0">{{ tsm }}</span>
                        </router-link>
                    </div>
                    <div class="mt-2 mb-2 text-center">
                        <router-link class="btn btn-red pad btn_vw" to="/incongruity">
                            Ежедневные Чек-листы <span class="badge rounded-pill text-bg-white ms-2" v-if="quality > 0">{{ quality }}</span>
                        </router-link>
                    </div>
                    <div class="mt-2 mb-2 text-center">
                        <router-link class="btn btn-red pad btn_vw" to="/penalties">
                            Штрафы
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from "../../components/Spinner";
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
    name: "SelectIncongruity",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            tsm: 0,
            car: 0,
            quality: 0,
            breadCrumbs: true,
            title: 'Несоответствия',
            back_title: 'Чек листы TSM',
            link: 'tsm',
        }
    },
    created() {
        this.getDefects();
    },
    methods:{
        async getDefects(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/defect/all/defects', config);
                this.tsm = response.data.data.tsm;
                this.quality = response.data.data.quality;
                this.car = response.data.data.car;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>
