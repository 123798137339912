<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="row mb-5">
                    <div class="col">
                        <h1>Добавить модуль</h1>
                    </div>
                </div>
                <Form ref="menu" @submit="create">

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Заголовок меню*</label>
                        <div class="col-sm-8 position-relative">
                            <Field
                                v-model="menu.title"
                                type="text"
                                name="title"
                                class="form-control"
                                :class="[errors.title.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="title" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Роут*</label>
                        <div class="col-sm-8 position-relative">
                            <Field
                                v-model="menu.path"
                                type="text"
                                name="path"
                                class="form-control"
                                :class="[errors.path.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="path" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Иконка*</label>
                        <div class="col-sm-8 position-relative">
                            <Field
                                v-model="menu.icon"
                                type="text"
                                name="icon"
                                class="form-control"
                                :class="[errors.icon.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="icon" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end">Номер сортировки</label>
                        <div class="col-sm-8 position-relative">
                            <Field
                                v-model="menu.sort"
                                type="text"
                                name="sort"
                                class="form-control"
                                :class="[errors.sort.length > 0 ? 'error' : '']"
                            />
                            <ErrorMessage name="sort" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label required text-end"></label>
                        <div class="col-sm-8 position-relative">
                            <button class="btn btn-green">
                                Сохранить
                            </button>
                        </div>
                    </div>


                </Form>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import { Field, Form, ErrorMessage } from 'vee-validate';
import spinner from '../../components/Spinner';
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
    name: "CreateModule",
    components: {
        spinner, Field, Form, ErrorMessage, BreadCrumbs
    },
    data(){
        return {
            menu:{
                title: null,
                path: null,
                icon: null,
                sort: null,
            },
            errors:{
                title: '',
                path: '',
                icon: '',
                sort: '',
            },
            isLoading: false,
            breadCrumbs: true,
            title: 'Добавить модуль',
            back_title: 'Список модулей',
            link: 'admins/module',
        }
    },
    methods: {
        create(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/modules', {
                title: this.menu.title,
                path: this.menu.path,
                icon: this.menu.icon,
                sort: this.menu.sort,
            }, config)
                .then(response => {
                    if(response.status == 200){
                        this.$router.push('/admins/module');
                    }
                })
                .catch(errors => {
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.menu.setErrors(errors.response.data.errors);
                            this.errors.title = errors.response.data.errors.title ?? 0;
                            this.errors.path = errors.response.data.errors.path ?? 0;
                            this.errors.icon = errors.response.data.errors.icon ?? 0;
                            this.errors.sort = errors.response.data.errors.sort ?? 0;
                            break;
                    }
                });
        }
    },
}
</script>

<style scoped>

</style>
