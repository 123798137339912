<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="d-flex flex-wrap" v-if="this.defects.length > 0">
                    <h5 class="mb-3"><fa icon="list-check" class="icon" style="padding-right: 10px; color: #c00;"></fa>{{ department }}</h5>
                    <div class="col-12 item_title"
                         v-for="defect in defects"
                         :key="defect.id"
                         @click="next(defect.id)"
                    >

                        <!-- Время от и до -->
                        <div class="gn_time_row d-flex justify-content-between">
                            <div class="gn_time_start text-black-50 fs-7">
                                <fa icon="clock" class="icon text-black-50"></fa>
                                {{ defect.start_date }}
                            </div>
                            <div class="gn_time_arrow">
                                <fa icon="angles-right" class="icon"></fa>
                            </div>
                            <div class="gn_time_end text-black-50 fs-7">{{ defect.term_execution }}</div>
                        </div>

                        <!-- Категория -->
                        <div class="gn_item mt-2">
                            <div class="gn_row d-flex gn_inner">
                                <div class="gn_label pe-2 text-black-50"><fa icon="list" class="icon"></fa></div>
                                <div class="gn_value">{{ defect.checks.name }}</div>
                            </div>
                        </div>

                    </div>
                </div>




            </div>
<!--            <div class="page">
                <div class="d-flex flex-wrap" v-if="this.defects.length > 0">

                </div>
                <div class="defect_check text-left align-middle d-flex align-items-center mb-3"
                     v-if="Object.keys(this.defects).length > 0"
                     v-for="defect in defects"
                     :key="defect.id"
                     @click="next(defect.id)"
                >
                    <div class="date_performance">
                        <span class="date_start">Время проверки {{ defect.start_date }}</span>
                        <span class="date_expires">Исполнить до {{ defect.term_execution }}</span>
                    </div>
                    <div style="margin: 0 auto;">{{ defect.checks.name }}</div>
                </div>
                <div class="text_end" v-else>
                    <div class="item_title text-center border-green mb-4">
                        Все несоответствия устранены
                    </div>
                </div>
             </div>-->
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "IncongruityPage",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            defects: {},
            department: '',
            breadCrumbs: true,
            title: 'Список несоответствий',
            back_title: 'Несоответствия',
            link: 'select-defects',
        }
    },
    created() {
        this.getDefects();
    },
    methods:{
        next(id){
            this.$router.push(`/incongruity/${this.$route.params.dep_id}/${id}`);
        },
        async getDefects(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/defect/quality/${this.$route.params.dep_id}`, config);
                this.defects = response.data.data.defects;
                this.department = response.data.data.department;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>
