<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page d-flex flex-column justify-content-center mt-0">
                <div class="block_select">
                    <div class="mb-2"
                         v-for="department in departments"
                         :key="department.id"
                        @click="next(department.id)"
                    >
                        <div class="btn btn-red pad btn_vw">
                            {{ department.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "Quality",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            departments: [],
            breadCrumbs: true,
            title: 'Список отделов',
            back_title: 'Список чек листов',
            link: 'check-list',
        }
    },
    created() {
        this.getDepartments();
    },
    methods: {
        next(id){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};

            axios.post(`/api/admin/quality/create/check`,{
                dep_id: id,
            }, config)
                .then(response => {
                    if(response.status == 200){
                        this.$router.push(`/quality/${id}`);
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        async getDepartments(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/quality/department', config);
                this.departments = response.data.data.departments;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        }
    }
}
</script>

<style scoped>

</style>
