<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="this.title"
                :back_title="this.back_title"
                :link="this.link" />
            <div class="page">
                <h5 class="mb-1 mt-3 hstack">
                    <Buying :count="9"/>
                    Комплектация
                </h5>
                <span class="d-block fs-7 fw-normal text-black-50">Отметьте комплектующие и аксессуары установленные на осматриваемом автомобиле.</span>
                <div>
                    <div v-for="list in lists" :key="list.id">
                        <h6 class="mb-2 mt-4">{{ list.name }}</h6>
                        <hr>
                        <div
                            class="btn cl_item cl_status pad mb-2 w-100 text-center hstack"
                            v-for="items in list.subitem"
                            :key="items.id"
                            :class="goClass(items.select)"
                            @click="addComplete(items.id)">
                            <span class="btn_complete_title">{{ items.name }}</span>
                            <span v-if="goClass(items.select) == 'type_accept'" class="material-symbols-rounded me-2 opacity-50 cl_status_icon">check_circle</span>
                            <span v-else class="material-symbols-rounded me-2 opacity-50 cl_status_icon">radio_button_unchecked</span>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <button class="btn btn-green w-100 mt-3 mb-5" @click="saveStep" >Сохранить</button>
        </div>
    </transition>

</template>

<script>
import Buying from "./Buying";
import axios from "axios";
import Spinner from '../../components/Spinner';
import BreadCrumbs from "../../Page/BreadCrumbs";

export default {
    name: "Step_9",
    components: {
        Buying,
        Spinner,
        BreadCrumbs
    },
    data: () => ({
        step: 'completeness',
        back_step: 'undersoot',
        lists: [],
        car: [],
        isLoading: false,
        breadCrumbs: true,
        title: "Комплектация",
        back_title: "Этапы приёма",
        link: "",
    }),
    created(){
        this.getData();
        this.getBackLink();
    },
    methods: {
        getBackLink() {
            this.link = this.link = `tradeinlist/${this.$route.params.id}`
        },
        saveStep(){
            this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/complete/save/step`, {
                id: this.$route.params.id,
                step_number: 9,
                status: 'accept',
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.$router.push(`/buying/completeness/${this.$route.params.id}`);
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        addComplete(id){
            //this.isLoading = true;
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post(`/api/admin/tradein/complete/add/complete`, {
                id: id,
                tradein_id: this.$route.params.id,
            }, config)
                .then(response =>{
                    if(response.status == 200){
                        this.getData();
                    }
                })
                .catch(error =>{
                    this.isLoading = false;
                    console.log(error);
                });
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/tradein/complete/${this.$route.params.id}`, config);
                this.lists = response.data.data.lists.data;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        goClass(data){
            if(data === true){
                return 'type_accept';
            }
        }
    }
}
</script>

<style scoped>

</style>
