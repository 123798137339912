<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page d-flex flex-column justify-content-center mt-0">
                <div class="block_select">
                    <div class="mb-2"
                         v-for="checklist in checklists"
                         :key="checklist.id"
                         @click="next(checklist.id)"
                    >
                        <div class="btn cl_item pad btn_vw"
                        >
                            {{ checklist.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from '../../../components/Spinner';
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "CarCheckListPage",
    components: {
        spinner, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            checklists: [],
            breadCrumbs: true,
            title: 'Чек лист Автомойка',
            back_title: 'Список чек листов',
            link: 'check-list',
        }
    },
    created() {
        this.getChecklists();
    },
    methods: {
        next(id) {
            this.$router.push(`/car/${id}`);
        },
        async getChecklists() {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get('/api/admin/car', config);
                this.checklists = response.data.data.checklists;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>
