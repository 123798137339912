<template>
    <!-- Организация -->
    <label class="form-label">Организация</label>
    <VueMultiselect
        v-model:modelValue="organization"
        placeholder="Выберите организацию"
        label="name"
        track-by="id"
        :options="organizations"
        name="organization"
        :class="errors.organization.length ? 'error' : ''"
        @select="clearInput('organization')"
        :show-labels="false"
    />

    <label class="form-label">Тип шаблона</label>
    <VueMultiselect
        v-model:modelValue="item"
        placeholder="Тип шаблона"
        label="name"
        track-by="id"
        :options="items"
        name="item"
        :class="errors.item.length ? 'error' : ''"
        @select="clearInput('item')"
        :show-labels="false"
    />

    <div class="mb-3">
        <label for="formFileMultiple" class="form-label">Загрузить шаблон</label>
        <input
            ref="files"
            class="form-control"
            type="file"
            id="formFileMultiple"
            v-on:change="handleFileUpload()"
            :class="errors.file.length ? 'error' : ''">
    </div>

    <Button
        caption="Сохранить"
        color="btn-green"
        add_class=""
        @click="save"
    />
</template>

<script>
import VueMultiselect from "vue-multiselect";
import Button from '../../../components/Button';
import Input from '../../../components/Input';

export default {
    name: "TDFormContract",
    components: {
        VueMultiselect, Button, Input
    },
    props: {
        id: null,
        organization: null,
        item: '',
        organizations: {
            type: Array,
            default: [],
        },
        items: {
            type: Array,
            default: [],
        },
        errors: {
            organization: '',
            item: '',
            file: '',
        }
    },
    data() {
        return {
            file: null,
        }
    },
    methods: {
        handleFileUpload() {
            this.errors.file = '';
            this.file = this.$refs.files.files[0];
        },
        save() {
            this.$emit('save', {
                id: this.id,
                item: this.item,
                file: this.file,
                organization: this.organization
            })
        },
        clearInput(e){
            this.errors[e] = '';
        },
    }
}
</script>

<style scoped>

</style>
