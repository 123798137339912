<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <h2 class="mb-3">Редактировать вопрос</h2>
                <Form ref="check" @submit="update">
                    
                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Отдел*</label>
                            <VueMultiselect
                                v-model="check.department"
                                placeholder="Выберите отдел"
                                label="name"
                                track-by="id"
                                :options="optionsDepartment"
                                :show-labels="false"
                                @tag="addDepartment"></VueMultiselect>
                            <ErrorMessage name="department" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Название вопроса*</label>
                            <Field
                                v-model="check.name"
                                type="text"
                                name="surname"
                                class="form-control"
                                :class="[errors.name.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="name" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Штраф*</label>
                            <Field
                                v-model="check.penalty"
                                type="text"
                                name="name"
                                class="form-control"
                                :class="[errors.penalty.length > 0 ? 'error' : '']"/>
                            <ErrorMessage name="penalty" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Срок исполнения*</label>
                            <VueMultiselect
                                v-model="check.deadline"
                                placeholder="Выберите срок"
                                label="time_performance"
                                track-by="id"
                                :options="deadlines"
                                :show-labels="false"
                                @tag="addDeadline"></VueMultiselect>
                            <ErrorMessage name="deadline" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Ответственные*</label>
                            <VueMultiselect
                                v-model="check.users"
                                placeholder="Выберите ответственных"
                                label="name"
                                track-by="id"
                                :options="options"
                                :multiple="true"
                                :show-labels="false"
                                @tag="addTag"></VueMultiselect>
                            <ErrorMessage name="users" style="color: red;"/>
                        </div>
                    </div>

                    <div class="row justify-content-end mt-3">
                        <div class="col col-lg-2">
                            <button class="btn btn-green w-100">
                                Сохранить
                            </button>
                        </div>
                    </div>

                </Form>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import { Field, Form, ErrorMessage } from 'vee-validate';
import spinner from '../../../components/Spinner';
import VueMultiselect from 'vue-multiselect';
import BreadCrumbs from "../../../Page/BreadCrumbs";

export default {
    name: "EditCheck",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs
    },
    data(){
        return {
            isLoading: false,
            deadlines: [],
            options: [],
            optionsDepartment: [],
            check: {
                name: null,
                penalty: null,
                deadline: null,
                users: null,
                department: null,
            },
            errors: {
                name: '',
                penalty: '',
                deadline: '',
                users: '',
                department: '',
            },
            breadCrumbs: true,
            title: 'Редактировать вопрос',
            back_title: 'Ежедневные чек листы',
            link: 'quality-setting',
        }
    },
    created(){
        this.getData();
        this.getCheckList();
    },
    methods: {
        async getCheckList(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/checklist/${this.$route.params.id}`, config);
                this.check = response.data.data.check;
                //this.check.users = response.data.data.check.user;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        async getData(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/quality/deadline', config);
                this.deadlines = response.data.data.deadlines;
                this.options = response.data.data.users;
                this.optionsDepartment = response.data.data.departments;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        addTag(newTag){
            const tag = {
                name: newTag,
            }
            this.options.push(tag)
            this.check.users.push(tag)
        },
        addDepartment(newDepartment){
            const department = {
                name: newDepartment,
            }
            this.optionsDepartment.push(department)
            this.check.department.push(department)
        },
        addDeadline(newDeadline){
            const deadline = {
                name: newDeadline,
            }
            this.deadlines.push(deadline)
            this.check.deadline.push(deadline)
        },
        update() {
            //this.isLoading = true;
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
            axios.post('/api/admin/checklist/update', {
                id: this.$route.params.id,
                name: this.check.name,
                deadline: this.check.deadline,
                penalty: this.check.penalty,
                users: this.check.users,
                department: this.check.department,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.$router.push('/quality-setting');
                    }
                })
                .catch(errors => {
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.check.setErrors(errors.response.data.errors);
                            this.errors.name = errors.response.data.errors.name ?? 0;
                            this.errors.deadline = errors.response.data.errors.deadline_id ?? 0;
                            this.errors.penalty = errors.response.data.errors.penalty ?? 0;
                            this.errors.users = errors.response.data.errors.users ?? 0;
                            this.errors.department = errors.response.data.errors.department_id ?? 0;
                            break;
                    }
                });
        }
    }
}
</script>

<style scoped>

</style>
