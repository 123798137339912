<template>
    <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Новый модуль</h5>
                    <button type="button" class="btn-close close_modal" @click="close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md">
                            <label class="form-label">Модуль*</label>
                            <VueMultiselect
                                v-model="module"
                                placeholder="Выберите модуль"
                                label="title"
                                track-by="id"
                                :options="modules"
                                :taggable="true"
                                @tag="addModule"
                                :show-labels="false"></VueMultiselect>
                            <ErrorMessage name="module" style="color: red;"/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn gray" @click="close">Закрыть</button>
                    <button type="button" class="btn btn-green" @click="create">Добавить</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";

export default {
    name: "AddModule",
    components: {
        VueMultiselect
    },
    props: {
        modules: {
            type: Array,
            default: [],
        },
        module: null,
    },
    methods: {
        close() {
            this.$emit('close-modal')
        },
        addModule(newModule){
            const module = {
                title: newModule,
            }
            this.module.push(module)
        },
        create(){
            this.$emit('add-module', {module: this.module})
        }
    }
}
</script>

<style scoped>

</style>
