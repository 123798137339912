<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                
                <!-- Управление -->
                <div class="d-flex col-12">
                    <div class="col-12">
                        <input type="date" class="form-control" v-model="selectDate" @change="handler">
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <router-link
                        to="/spoof/create"
                        class="btn w-100 btn-green">
                        <fa icon="plus" class="icon opacity-50 me-1"/>
                        {{ $t('request.button.create') }}
                    </router-link>
                </div>


                <div v-if="Object.keys(this.contracts).length > 0">
                    <div 
                        v-for="contract in contracts" 
                        :key="contract.id" 
                        @click="selectContract(contract.id)"
                        class="item_title mb-2">

                        <!-- номер, дата, статус -->
                        <div class="border-bottom col-12 d-flex fw-semibold justify-content-between mb-2 pay_number pb-3">
                            <div class="fs-6 fw-bold">№{{ contract.id }}</div>
                            <div class="fs-7">{{ contract.date_created }}</div>
                            <div class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block denied" v-if="contract.status == 'is_create'">Создано</div>
                            <div class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block in_work" v-if="contract.status == 'is_start'">В пути</div>
                            <div class="fs-7 fw-normal rounded-pill px-2 text-center d-inline-block completed" v-if="contract.status == 'is_finish'">Завершено</div>
                        </div>


                        <!-- Старт, Финиш  -->
                        <div class="col-12 border-bottom d-flex justify-content-between mb-3 pb-2">
                            <div>
                                <fa class="icon opacity-50 me-2" icon="car-on"></fa>
                                <span class="fs-7 fw-normal pe-2 text-black-50">Старт:</span>
                                <span class="d-block fw-bold w-100" v-if="contract.date_start == '...'">ожидается</span>
                                <span class="d-block fw-bold w-100" v-else>{{ contract.date_start }}</span>

                            </div>
                            <div>
                                <fa class="icon opacity-50 me-2" icon="flag-checkered"></fa>
                                <span class="fs-7 fw-normal pe-2 text-black-50">Финиш:</span>
                                <span class="d-block fw-bold w-100" v-if="contract.date_end == '...'">ожидается</span>
                                <span class="d-block fw-bold w-100" v-else>{{ contract.date_end }}</span>
                            </div>
                        </div>

                        <!-- Карточка договора -->
                        <div class="col-12 border-bottom mb-3 pb-3">
                            <div class="mb-2">
                                <fa class="icon opacity-50 me-2" icon="car"></fa>
                                <span class="fs-6 fw-bold">{{ contract.car }}</span>
                            </div>
                            <div>
                                <fa class="icon opacity-50 me-2" icon="barcode"></fa>
                                <span class="fs-6 fw-bold">{{ contract.car_number }}</span>
                            </div>
                        </div>

                        <div class="col-12">
                            <div  class="mb-2">
                                <fa class="icon opacity-50 me-2" icon="user"></fa>
                                <span class="fw-normal">{{ contract.fullNameClient }}</span>
                            </div>
                            <div>
                                <fa class="icon opacity-50 me-2" icon="user-tie"></fa>
                                <span class="fw-normal">{{ contract.fullNameManager }}</span>
                            </div>
                        </div>


                    </div>
                </div>
                <div v-else>
                    <div class="item_title fw-semibold">
                        На указанную дату договоров нет.
                    </div>
                </div>
                
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import spinner from "../../../components/Spinner";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import ToastAlert from '../../../components/ToastAlert';

export default {
    name: "SpoofIndex",
    components: {
        spinner, BreadCrumbs, ToastAlert
    },
    data(){
        return {
            selectDate: null,
            message: '',
            bg: '',
            is_alert: false,
            is_modal: false,
            testDriveId: null,
            contracts: [],
            isLoading: false,
            breadCrumbs: true,
            title: 'Список договоров',
            back_title: 'Категории',
            link: 'td',
        }
    },
    created(){
        this.getContracts(this.selectDate);
    },
    methods: {
        handler(){
            this.getContracts(this.selectDate);
        },
        async getContracts(date) {
            try {
                this.isLoading = true;
                const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
                const response = await axios.get(`/api/admin/spoof/index/${date}`, config);
                this.contracts = response.data.data.contracts;
                this.selectDate = response.data.data.selectDate;
                console.log(this.selectDate);
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        selectContract(id){
            if(!this.is_sb){
                this.$router.push(`/spoof/contract/${id}`)
            }
        },
    }
}
</script>

<style scoped>

</style>
