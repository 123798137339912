<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div class="container-fluid login_top">
            <div class="row">
                <div class="login_page_wrap align-items-center d-flex h-100 justify-content-start position-absolute col-12 g-0" v-if="is_login">
                    <div class="align-items-center col-12 col-lg-5 col-md-6 col-xl-4 col-xxl-3 d-flex h-100 justify-content-center login_box">
                        <div class="auth_box">
                            <Form ref="form" @submit="login">
                                <div class="logo">
                                    <img class="mb-4 logo-img" src="/image/logo.png" alt="">
                                </div>

                                <div class="form-signin">
                                    <div class="login_input_box">
                                        <Field name="phone" type="tel" class="form-control input_login" placeholder="+7 (___) ___-__-__"
                                               @input="errorClear()"
                                               v-model="form.phone"
                                               :rules="validatePhone"
                                               :class="[errors.phone.length > 0 ? 'error' : '']"
                                               v-telmask
                                        />
                                        <ErrorMessage name="phone"/>
                                    </div>
                                    <div class="login_input_box">
                                        <Field name="password" type="password" class="form-control mt-2 input_login" placeholder="Пароль"
                                               @input="errorClear()"
                                               v-model="form.password"
                                               :rules="validatePassword"
                                               :class="[errors.password.length > 0 ? 'error' : '']"
                                        />
                                        <ErrorMessage name="password" />
                                    </div>
                                    <span v-if="password_error" role="alert" class="mt-3">{{ errors.password }}</span>
                                    <a style="cursor:pointer;" @click="emailPageShow()" class="my-2 fs-7 text-danger hstack text-decoration-none">
                                        <span class="material-symbols-rounded me-2">key</span>
                                        Восстановить пароль
                                    </a>
                                    <button class="w-100 btn btn-lg btn-red mt-3">
                                        <span class="material-symbols-rounded me-2 opacity-50">login</span>
                                        Войти
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <transition name="fade">
        <div
            v-if="is_tg"
        >
            <div class="form-tg">
                <div class="alert alert-info" role="alert" style="font-size: 20px;">
                    Для начала работы необходимо привязать Telegram-аккаунт!
                </div>
                <div class="card text-start">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">1. Перейдите в телеграм-бот</li>
                        <li class="list-group-item">2. Нажмите кнопку "Запустить"</li>
                        <li class="list-group-item">3. Вернитесь к приложению</li>
                    </ul>
                </div>
                <a class="w-100 btn btn-lg btn-red mt-3"
                   target="_blank" :href="link"
                >Перейти в телеграм бот</a>
            </div>
        </div>
    </transition>

</template>

<script>
import axios from "axios";
import spinner from '../components/Spinner';
import {Field, Form, ErrorMessage} from 'vee-validate';
import * as auth from '../services/auth';

const telmask = {
    mounted: (el, binding) => {
        el.onfocus = function (e) {
            this.value = '+7 ('
            this.setSelectionRange(4, 4)
        }
        el.oninput = function (e) {
            if (!e.isTrusted) {
                return
            }
            const x = this
                .value
                .replace(/\D/g, '')
                .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
            x[1] = '+7'
            this.value = !x[3]
                ? x[1] + ' (' + x[2]
                : x[1] + ' (' + x[2] + ') ' + x[3] + (
                    x[4]
                        ? '-' + x[4]
                        : ''
                ) + (
                    x[5]
                        ? '-' + x[5]
                        : ''
                )
        }
    }
}

export default {
    name: "Login",
    components: {
        spinner, Field, Form, ErrorMessage
    },
    directives: {
        telmask
    },
    data() {
        return {
            loggedIn: false,
            form: {
                phone: null,
                password: null
            },
            isLoading: false,
            errors: {
                phone: '',
                password: '',
            },
            is_login: true,
            is_tg: false,
            link: '',
            password_error: false,
        }
    },
    methods: {
        emailPageShow(){
            this.$emit('reset-password')
            this.$router.push('/reset-password')
        },
        errorClear(){
            this.password_error = false;
        },
        validatePhone(value) {
            if (!value) {
                return 'Поле номер телефона обязательно';
            }
            return true;
        },
        validatePassword(value) {
            if (!value) {
                return 'Поле пароль обязательно';
            }
            return true;
        },
        login() {
            axios.post('api/login', {
                password: this.form.password,
                phone: this.form.phone,
            })
                .then(response => {
                    if (response.data.status === true) {
                        if (response.data.data.message == 'success') {
                            this.$cookie.setCookie('token', response.data.data.api_token);
                            if (response.data.data.telegramId === true) {
                                this.$router.go('/main');
                            } else {
                                this.link = response.data.data.link
                                this.is_login = false;
                                this.is_tg = true;
                            }
                        }
                    }
                })
                .catch(errors => {

                    switch (errors.response.status) {
                        case 422:
                            this.$refs.form.setErrors(errors.response.data.errors);
                            this.errors.phone = errors.response.data.errors.phone ?? 0;
                            this.errors.password = errors.response.data.errors.password ?? 0;
                            break;
                        case 401:
                            this.password_error = true
                            this.errors.password = errors.response.data.errors.message ?? 0;
                            break;

                    }
                });
        },
    },
}
</script>

<style scoped>
.error {
    border: 1px solid red !important;
}

</style>
