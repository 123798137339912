import imprest from "./imprest";
import RequestPage from "../../Modules/Request/RequestPage";
import * as auth from "../../services/auth";
import settings from "./settings";
import memos from "./memos";
import payments from "./payments";
import counteragents from "./counteragents";

export default [
    {
        name: 'RequestPage',
        path: '/request',
        component: RequestPage,
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                next();
            }
        }
    },
    {
        path: '/request/career',
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                window.open('https://docs.google.com/forms/d/e/1FAIpQLSc5GijSM6WmILrWcllWtSaLUUAGWShFgJtB9XRR_JG2EyRoJg/viewform?usp=sf_link', '_blank');
            }
        }
    },
    {
        path: '/request/kaizen',
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/')
            } else {
                window.open('https://docs.google.com/forms/d/e/1FAIpQLSfkBdPg4FVI6FlcRVQLCVcEgoPDj_Manx37nTiCYWBY31CXdg/viewform', '_blank');
            }
        }
    },
    ...settings,
    ...imprest,
    ...memos,
    ...payments,
    ...counteragents,
]
