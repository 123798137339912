<template>
    <spinner v-if="isLoading"></spinner>
    <div>
        <BreadCrumbs
            :breadCrumbs="breadCrumbs"
            :title="title"
            :back_title="back_title"
            :link="link"
        />
        <div class="page">
            <div class="item_title">

                <button
                    class="btn gray w-100"
                    v-if="contains(actions, 'vygruzka-v-eksel')"
                    @click="exportInPDF">
                    <fa class="icon me-2" icon="file-pdf"></fa>
                    Скачать отчёт
                </button>

                <div class="border-bottom hstack justify-content-between mt-3 pb-3">
                    <span class="fs-7 pe-2 fw-normal">За период:</span> 
                    <span class="fs-4 ps-2">{{ total.toLocaleString() }} ₸</span>
                </div>

                <!-- Accordion Flash -->
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed ps-0 pb-1 fs-7" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                <fa class="icon pe-2 text-black-50" icon="filter"></fa>Настроить фильтр
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            
                            <VueMultiselect
                                class="mt-3"
                                v-model="department"
                                placeholder="Выбрать отдел"
                                label="name"
                                track-by="id"
                                :options="departments"
                                :show-labels="false"
                                :searchable="false"
                                @select="selectDep"
                                @remove="resmoveDep"></VueMultiselect>

                            <VueMultiselect
                                v-model="user"
                                placeholder="Выбрать сотрудника"
                                label="name"
                                track-by="id"
                                :options="users"
                                :show-labels="false"
                                :searchable="false"
                                @select="selectUser"
                                @remove="removeUser"></VueMultiselect>

                            <div class="col-12 hstack gap-3">
                                <input type="date" class="form-control" v-model="startDay" @change="handler()">
                                <input type="date" class="form-control" v-model="endDay" @change="handler()">
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="item_title">
                <table class="table">
                            <thead>
                            <tr>
                                <th scope="col">Сотрудник</th>
                                <th scope="col" class="text-end">Штраф</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="result in results"
                                :key="result.index"
                            >
                                <td>{{ result.name }}</td>
                                <td class="text-end">{{ result.sum.toLocaleString() }} ₸</td>
                            </tr>
                            </tbody>
                        </table>
            </div>

            <div class="item_title">
<div class="table-responsive-md">
    <table class="table table-hover fs-7">
        <thead>
            <tr>
                <th class="text-muted">Вопрос</th>
                <th class="text-muted">Дата штрафа</th>
                <th class="text-muted">Сумма штрафа</th>
                <th class="text-muted">Ответственные</th>
                <th class="text-muted">Комментарий</th>
                <th class="text-muted">Статус</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="report in reports" :key="report.id">
                <td>
                    {{ report.checks.name }}
                </td>
                <td>
                    {{ report.date_fine }}
                </td>
                <td>
                    {{ report.checks.penalty }}
                </td>
                <td>
                    <div v-for="item in report.user" :key="item.id">
                        <div>{{ item.surname }}
                            {{ item.name }}</div>
                    </div>
                </td>
                <td>
                    {{ report.comment }}
                </td>
                <td>
                    <span :class="report.status">{{ getStatus(report.status) }}</span>
                </td>
                <td
                    class="text-end"
                    style="width: 5%;"
                    v-if="contains(actions, 'redaktirovat-otcet')">
                    <div class="dropdown">
                        <button
                            class="btn btn_drop px-0"
                            type="button"
                            data-bs-toggle="dropdown"
                            id="dropdownMenuButton">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#ccc"
                                class="bi bi-three-dots-vertical"
                                viewBox="0 0 16 16">
                                <path
                                    d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            </svg>
                        </button>
                        <ul
                            class="dropdown-menu dropdown-menu-end"
                            aria-labelledby="dropdownMenuButton">
                            <li>
                                <button
                                    type="button"
                                    @click="$router.push(`/report/edit/${report.id}`)"
                                    class="dropdown-item">Редактировать</button>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
            </div>
        
    </div>
    </div>
</template>

<script>
import axios from "axios";
import spinner from "../../../components/Spinner";
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../../Page/BreadCrumbs";
import {getUserPermissionActions} from "../../../services/helpers";
import { localeData } from "moment";

export default {
    name: "ReportPage",
    components: {
        spinner, VueMultiselect, BreadCrumbs
    },
    data(){
        return {
            reports: [],
            results: [],
            departments: [],
            department: null,
            users: [],
            user: null,
            isLoading: false,
            startDay: null,
            endDay: null,
            total: 0,
            breadCrumbs: true,
            title: 'Журнал штрафов',
            back_title: 'Чек листы TSM',
            link: 'tsm',
            modules: [],
            actions: [],
        }
    },
    created() {
        this.getReports();
        this.getUserActions();
    },
    methods: {
        async getUserActions(){
            this.actions = await getUserPermissionActions(this.$route.path);
        },
        selectUser(value){
            this.user = value;
            this.updateReports();
        },
        contains(arr, elem){
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].alias === elem) {
                    return true;
                }
            }
            return false;
        },
        removeUser(){
            this.user = null;
            if(this.department != null){
                this.getUser(this.department.id);
            }else{
                this.getUser(null);
            }
            this.updateReports();
        },
        selectDep(value){
            this.user = null;
            this.department = value;
            this.getUser(value.id);
            this.updateReports();
        },
        resmoveDep(){
            this.department = null;
            this.getUser(null);
            this.updateReports();
        },
        handler(){
            this.updateReports();
        },
        async getUser(id){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/report/get/user/${id}`, config);
                this.users = response.data.data.users;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        async getReports(){
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get('/api/admin/report', config);
                this.reports = response.data.data.reports;
                this.startDay = response.data.data.startDay;
                this.endDay = response.data.data.endDay;
                this.results = response.data.data.results;
                this.total = response.data.data.total;
                this.departments = response.data.data.departments;
                this.users = response.data.data.users;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
        exportInPDF(){
            let dep = null;
            let userId = null;

            if(this.department != null){
                dep = this.department.id;
            }

            if(this.user != null){
                userId = this.user.id;
            }

            let element = document.createElement("a");
            let url = "/api/download/quality/pdf/" + this.startDay +'/'+ this.endDay +'/'+dep+'/'+ userId;
            element.setAttribute("href", url);
            element.setAttribute("download", "Report.pdf");
            element.setAttribute("target", "_blank");
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        updateReports(){
            const config = {'headers': {'Authorization': 'Bearer ' + this.$cookie.getCookie('token')}};
            axios.post('/api/admin/report/data', {
                data_start: this.startDay,
                data_end: this.endDay,
                department: this.department,
                user: this.user,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.results = response.data.data.results;
                        this.reports = response.data.data.reports;
                        this.total = response.data.data.total;
                    }
                })
                .catch(errors => {
                    switch (errors.response.status) {
                        case 422:
                            this.$refs.check.setErrors(errors.response.data.errors);
                            this.errors.name = errors.response.data.errors.name ?? 0;
                            this.errors.penalty = errors.response.data.errors.penalty ?? 0;
                            this.errors.users = errors.response.data.errors.users ?? 0;
                            this.errors.comment = errors.response.data.errors.comment ?? 0;
                            break;
                    }
                });
        },
        getStatus(status){
            if(status == 'penalty'){
                return 'Начислен';
            }else if(status == 'resolved'){
                return 'Оспорен';
            }
        }
    }
}
</script>

<style scoped>
    .penalty {
        background-color: #CC0000!important;
        color: #FFFFFF!important;
        padding: 5px;
        border-radius: 5px;
    }
    .resolved {
        background-color: #009E23!important;
        color: #FFFFFF!important;
        padding: 5px;
        border-radius: 5px;
    }
</style>
